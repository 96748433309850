import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const UZCookie = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Cookie-Fayllar</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Cookie-Fayllar</h2>
                <br/>Saytdan foydalanganda sizga ko'proq imkoniyatlarni taqdim etish uchun cookie-fayllardan
                <br/>foydalanamiz.
                <br/><br/>Cookie-fayllar brauzeringiz tomonidan veb-saytimizda navigatsiya qilish uchun zarur bo'lgan
                <br/>kompyuterning qattiq diskida vaqtincha saqlash uchun joylashtirilgan kichik ma'lumotlar
                <br/>bloklari.
                <br/><br/>Cookie-fayllarda siz haqingizda shaxsiy ma'lumotlar mavjud emas va shaxsiy foydalanuvchini
                <br/>aniqlash uchun foydalanilmaydi.
                <br/><br/>Cookie-fayllarda qurilmangizda saqlanadigan anonim raqam (tasodifiy yaratilgan) bo'lgan noyob
                <br/>identifikatorni o'z ichiga oladi. Ba'zi fayllar saytdagi ish seansi oxirida o'chiriladi; boshqalar
                <br/>kompyuteringizda uzoqroq qoladi.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Cookies fayl turlari</h2>
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Mahalliy cookie fayllari</h2>
                <br/>Mahalliy cookie-fayllar siz tashrif buyuradigan sayt tomonidan o'rnatiladi va faqat ushbu sayt
                <br/>tomonidan o'qilishi mumkin.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Uchinchi tomon cookie fayllari</h2>
                <br/>Uchinchi tomon cookie fayllari biz foydalanadigan boshqa tashkilotlar tomonidan o'rnatiladi.
                <br/>Misol uchun, biz uchinchi tomon analitik xizmatlaridan foydalanamiz va ushbu xizmatlarning
                <br/>provayderlari bizning veb-saytimizdagi qaysi bo'limlar mashhur va qaysi biri yo'qligi haqida bizga
                <br/>xabar berish uchun bizning nomimizdan cookie-fayllarni o'rnatadilar. Siz tashrif buyuradigan
                <br/>sayt, masalan, YouTube-dan yuklab olinadigan materiallarni o'z ichiga olishi mumkin va bu
                <br/>saytlar cookie-fayllarini o'rnatishi mumkin.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Nima uchun Henkel cookie-fayllardan foydalanadi?</h2>
                <br/><br/>Cookie-fayllardagi ma'lumotlar, masalan, xizmatlarning ishlashini yaxshilash uchun ishlatiladi:
                <br/>xizmat sizning qurilmangizni tanib olish imkonini beradi, shuning uchun siz bir xil ma'lumotni bir
                <br/>vazifa yoki sessiya davomida bir necha marta kiritishingiz shart emas, masalan, veb-shakl yoki
                <br/>so'rovnomani to'ldirish
                <br/>"mening tarkibim" funksiyasini qo'llab-quvvatlash
                <br/>video pleerning to'g'ri ishlashini ta'minlash
                <br/>xizmatdan foydalanadigan odamlar sonini aniqlash, bu xizmatlardan foydalanishni
                <br/>osonlashtiradi va tezkor ishlashni ta'minlash uchun etarli ish faoliyatini ta'minlaydi; anonim
                <br/>ma'lumotlarni tahlil qilish, odamlarning turli xil onlayn xizmatlar bilan qanday ishlashini
                <br/>tushunishimizga imkon beradi, shuning uchun biz ularni yaxshiroq qilishimiz mumkin.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Ushbu sayt tomonidan ishlatiladigan cookie-fayllar ro'yxati:</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>1. Google Analytics</h3>
                <br/><br/>heli-google analytics
                <br/>__utmt_UA-XXX
                <br/>__utma
                <br/>__utmb
                <br/>__utmc
                <br/>__utmz
                <br/><br/>Ushbu cookie fayllari bizning saytimiz va uning mazmuni haqida ma'lumot to'plash uchun
                <br/>ishlatiladi
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>2. Disable Google Analytics</h3>
                <br/>ga-disable-UA-XX
                <br/><br/>Agar ushbu cookie-fayl o'rnatilgan bo'lsa, bizning saytimizdan foydalanish haqida ma'lumot
                <br/>to'planmaydi
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>3. Youtube</h3>
                <br/><br/>VISITOR_INFO01_LIVE
                <br/>YSC
                <br/>GEUP
                <br/>PREF
                <br/><br/>Ushbu cookie fayllari uchinchi tomon tomonidan qo'shilgan: "YouTube"video portali. Bu bizning
                <br/>veb-saytida video foydalanish haqida ma'lumot to'playdi. Ushbu cookie - faylni o'rnatish
                <br/>saytdagi videodan foydalanishga bog'liq emas.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>4. JSESSIONID</h3>
                <br/>JSESSIONID
                <br/>Bizning aloqa shaklimizda "captcha"deb nomlangan narsa mavjud. Bu bizning shaklimizni
                <br/>suiiste'mol qilishdan himoya qiladigan odam tomonidan hal qilinishi mumkin bo'lgan oddiy
                <br/>vazifadir. Ushbu cookie sizni hal qilishni so'ragan "captcha" ni aniqlash uchun ishlatiladi.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>5. DoubleClick</h3>
                <br/>id
                <br/>brauzeringizga Google DoubleClick xizmati uchun taxallusli identifikatsiya raqamini beradi.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>6. Boshqa</h3>
                <br/>Yuqoridagi cookie-fayllarga qo'shimcha ravishda, iframes, JavaScript va boshqalar kabi
                <br/>texnologiyalar yordamida ushbu saytga joylashtirilgan kontentda (tashqi) qo'shimcha cookie-
                <br/>fayllar, mahalliy yoki uchinchi tomon ishlab chiquvchilaridan foydalanish mumkin.
                <br/><br/>Cookie - fayllardan foydalanishga ruxsat berib, siz ushbu kontentda cookie-fayllardan
                <br/>foydalanishingiz mumkinligini tan olasiz.
                <br/><br/>Henkel uchinchi tomon cookie-fayllarini nazorat qilmaydi va javobgar bo'lmaydi. Qo'shimcha
                <br/>ma'lumotni ishlab chiqaruvchining veb-saytidan topishingiz mumkin.
            </div>
            <Footer/>
        </div>
    )
}
