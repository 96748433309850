import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Cookie = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Файлы Cookie</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40, lineHeight:1.4}}>
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Файлы Cookie</h2>
                <br/>Мы используем файлы cookie для того, чтобы предоставить Вам больше
                <br/>возможностей при использовании сайта.
                <br/>Файлы cookie представляют собой небольшие блоки данных, помещаемые Вашим
                <br/>браузером на временное хранение на жестком диске Вашего компьютера,
                <br/>необходимые для навигации по нашему сайту.
                <br/>Файлы cookie не содержат никакой личной информации о Вас и не могут
                <br/>использоваться для идентификации отдельного пользователя.
                <br/>Файл cookie часто содержит уникальный идентификатор, представляющий собой
                <br/>анонимный номер (генерируемый случайным образом), сохраняемый на Вашем
                <br/>устройстве. Некоторые файлы удаляются по окончании Вашего сеанса работы на
                <br/>сайте; другие остаются на Вашем компьютере дольше.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Типы файлов cookies</h2>
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Собственные файлы cookie</h2>
                <br/>Собственные файлы cookie устанавливаются сайтом, который Вы посещаете, и могут
                <br/>считываться только этим сайтом.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Файлы cookie сторонних разработчиков</h2>
                <br/>Файлы cookie сторонних разработчиков устанавливаются другими организациями,
                <br/>сервисы которых мы используем. Например, мы используем сторонние
                <br/>аналитические сервисы, и провайдеры этих сервисов устанавливают файлы cookie от
                <br/>нашего имени, чтобы сообщать нам о том, какие из разделов на нашем сайте
                <br/>являются популярными, а какие нет. Сайт, который Вы посещаете, может содержать
                <br/>материалы, загружаемые, например, с YouTube, и эти сайты могут устанавливать
                <br/>свои файлы cookie.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Почему Henkel использует файлы cookie?</h2>
                <br/>Информация, содержащаяся в файлах cookie, используется для улучшения работы
                <br/>сервисов, например:
                <br/>• позволяет сервису распознавать Ваше устройство, чтобы Вам не приходилось
                <br/>вводить одну и ту же информацию несколько раз для одной задачи или в течение
                <br/>сеанса, например, заполнение web-формы или анкеты
                <br/>• поддержка функции «Мой контент»
                <br/>• обеспечение правильного функционирования видеоплеера
                <br/>• определение количества людей, использующих сервис, что позволяет облегчить
                <br/>пользование сервисами и обеспечить достаточную производительность для
                <br/>поддержания быстрой работы; анализ анонимных данных, позволяющий нам
                <br/>понять, как люди взаимодействуют с различными видами наших онлайн-сервисов,
                <br/>чтобы мы могли сделать их лучше.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Список cookie файлов, которые используются данным сайтом:</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>1. Google Analytics</h3>
                <br/>• heli-googleanalytics
                <br/>• __utmt_UA-XXX
                <br/>• __utma
                <br/>• __utmb
                <br/>• __utmc
                <br/>• __utmz
                <br/>Эти файлы cookie используются для сбора информации об использовании нашего
                <br/>сайта и его содержания
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>2. Disable Google Analytics</h3>
                <br/>• ga-disable-UA-XXX
                <br/>Если данный файл cookie установлен, данные об использовании Вами нашего сайта
                <br/>не собираются
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>3. Youtube</h3>
                <br/>• VISITOR_INFO01_LIVE
                <br/>• YSC
                <br/>• GEUP
                <br/>• PREF
                <br/>Данные файлы cookie добавлены третьей стороной: видео-порталом «YouTube». Он
                <br/>собирает информацию об использовании видео на нашем сайте. Установка данного
                <br/>файла cookie НЕ зависит от использования видео на сайте.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>4. JSESSIONID</h3>
                <br/>• JSESSIONID
                <br/>Наша контактная форма содержит так называемую «капчу». Это простая задача,
                <br/>которая может быть решена только человеком, что защищает нашу форму от
                <br/>злоупотребления. Этот файл cookie используется для распознания «капчи», которую
                <br/>Вас попросили решить.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>5. DoubleClick</h3>
                <br/>• id
                <br/>присваивает Вашему браузеру псевдонимный идентификационный номер для
                <br/>сервиса DoubleClick от Google.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>6. Прочее</h3>
                <br/>Кроме вышеперечисленных файлов cookie, могут использоваться дополнительные
                <br/>файлы cookie, собственные или сторонних разработчиков, в контенте (внешнем),
                <br/>встраиваемом на данный сайт с помощью таких технологий, как iFrames, JavaScript и
                <br/>т.д.
                <br/>Разрешая использование файлов cookie, Вы также признаете, что в подобном
                <br/>контенте могут использоваться свои файлы cookie.
                <br/>Компания Henkel не контролирует и не несет ответственность за файлы cookie
                <br/>сторонних разработчиков. Дополнительную информацию Вы можете найти на сайте
                <br/>разработчика.
                {/*<br/>Біз cookie файлдарын сайты пайдалану кезінде Сізге көбірек мүмкіндіктер беру үшін*/}
                {/*<br/>қолданамыз.*/}
                {/*<br/><br/>Cookie файлдары бұл біздің сайт бойынша навигация үшін қажетті, браузеріңіз*/}
                {/*<br/>компьютеріңіздің қатты дискісіне уақытша сақтауға орналастыратын деректердің*/}
                {/*<br/>шағын блоктары болып табылады.*/}
                {/*<br/><br/>Cookie файлдары Сіз туралы жеке ақпаратты қамтымайды және жеке пайдаланушыны*/}
                {/*<br/>сәйкестендіру үшін пайдаланылмайды.*/}
                {/*<br/><br/>Cookie файлында көбінесе Сіздің құрылғыңызда сақталатын анонимді нөмір (кездейсоқ*/}
                {/*<br/>пайда болатын) болып табылатын бірегей идентификатор болады. Кейбір файлдар*/}
                {/*<br/>сайттағы жұмыс сеансының соңында жойылады; басқалары Сіздің компьютеріңізде*/}
                {/*<br/>ұзақ сақталады.*/}
                {/*<br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Cookies файлдарының түрлері</h2>*/}
                {/*<br/><h2 style={{fontWeight:"bold", margin:0}}>Жеке cookie файлдары</h2>*/}
                {/*<br/>Жеке cookie файлдары сіз кіретін сайтпен орнатылады және тек осы сайтпен оқылуы*/}
                {/*<br/>мүмкін.*/}
                {/*<br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Бөгде әзірлеушілердің cookie файлдары</h2>*/}
                {/*<br/>Бөгде әзірлеушілердің cookie файлдары сервистерін біз пайдаланатын басқа*/}
                {/*<br/>ұйымдармен орнатылады. Мысалы, біз бөгде аналитикалық сервистерді пайдаланамыз*/}
                {/*<br/>және осы сервистердің провайдерлері біздің сайттағы бөлімдердің қайсысы танымал*/}
                {/*<br/>және қайсысы танымал емес екендігі туралы хабарлау үшін біздің атымыздан cookie*/}
                {/*<br/>файлдарын орнатады. Сіз кірген сайтта, мәселен, YouTube-тен жүктелген материалдар*/}
                {/*<br/>болуы мүмкін және бұл өз сайттар cookie файлдарын орната алады.*/}
                {/*<br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Неліктен Henkel cookie файлдарын пайдаланады?</h2>*/}
                {/*<br/>Cookie файлдарындағы ақпарат сервистердің жұмысын жақсарту үшін пайдаланылады,*/}
                {/*<br/>мысалы:*/}
                {/*<br/><br/>• қызметке Сіздің құрылғыңызды тануға мүмкіндік береді, сондықтан бір*/}
                {/*<br/>тапсырма үшін немесе сессия барысында бірдей ақпаратты бірнеше рет*/}
                {/*<br/>енгізудің, мысалы, web-нысанды немесе сауалнаманы толтырудың қажеті жоқ*/}
                {/*<br/>• «Менің контентім» функциясын қолдау*/}
                {/*<br/>• бейне ойнатқыштың дұрыс жұмыс істеуін қамтамасыз ету*/}
                {/*<br/>• Сервисті пайдаланатын адамдардың санын анықтау – сервистерді пайдалануды*/}
                {/*<br/>жеңілдету және жылдам жұмысты қамтамасыз ету үшін жеткілікті өнімділікті*/}
                {/*<br/>қамсыздандыруға мүмкіндік береді; анонимді деректерді талдау – онлайн-*/}
                {/*<br/>сервистердің әртүрлі түрлерімен адамдардың қалай қарым-қатынас*/}
                {/*<br/>жасайтындығын түсінуге мүмкіндік береді. Осылайша біз оларды жақсарта*/}
                {/*<br/>аламыз.*/}
                {/*<br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Осы сайт пайдаланатын cookie файлдарының тізімі:</h2>*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>1. Google Analytics</h3>*/}
                {/*<br/>• heli-googleanalytics*/}
                {/*<br/>• __utmt_UA-XXX*/}
                {/*<br/>• __utma*/}
                {/*<br/>• __utmb*/}
                {/*<br/>• __utmc*/}
                {/*<br/>• __utmz*/}
                {/*<br/>Бұл cookie файлдары біздің сайтты пайдалану және оның мазмұны туралы ақпаратты*/}
                {/*<br/>жинау үшін қолданылады*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>2. Disable Google Analytics</h3>*/}
                {/*<br/>• ga-disable-UA-XXX*/}
                {/*<br/>Егер осы cookie файлы орнатылса, біздің сайты пайдалануыңыз туралы деректеріңіз*/}
                {/*<br/>жиналмайды*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>3. Youtube</h3>*/}
                {/*<br/>• VISITOR_INFO01_LIVE*/}
                {/*<br/>• YSC*/}
                {/*<br/>• GEUP*/}
                {/*<br/>• PREF*/}
                {/*<br/>Бұл cookie файлдары үшінші тараппен: «YouTube» бейне-порталымен қосылған.*/}
                {/*<br/>Ол біздің сайта бейнені пайдалану туралы ақпарат жинайды.*/}
                {/*<br/>Бұл cookie файлын орнату сайттағы бейнені пайдалануға БАЙЛАНЫСТЫ ЕМЕС.*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>4. JSESSIONID</h3>*/}
                {/*<br/>• JSESSIONID*/}
                {/*<br/>Біздің байланыс формамыз «капчадан» тұрады. Бұл біздің формамызды теріс*/}
                {/*<br/>пайдаланудан қорғайтын адам ғана шеше алатын қарапайым міндет. Бұл cookie файлы*/}
                {/*<br/>Сіз шешуді сұраған «капчаны» анықтау үшін қолданылады.*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>5. DoubleClick</h3>*/}
                {/*<br/>• id*/}
                {/*<br/>Google-ден DoubleClick сервисіне арналған сәйкестендіру нөмірін Сіздің браузеріңізге*/}
                {/*<br/>меншіктейді.*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>6. Басқа</h3>*/}
                {/*<br/>Жоғарыда аталған cookie файлдарынан басқа, iFrames, JavaScript және т. б. сияқты*/}
                {/*<br/>технологиялардың көмегімен осы сайтқа енгізілген контентте (сыртқы) қосымша*/}
                {/*<br/>cookie файлдары, жеке немесе үшінші тарап әзірлеушілерінің cookie файлдары*/}
                {/*<br/>пайдаланылуы мүмкін.*/}
                {/*<br/>Cookie файлдарын пайдалануға рұқсат бере отырып, Сіз сондай контентте өз cookie*/}
                {/*<br/>файлдарын пайдалануға болатындығын мойындайсыз.*/}
                {/*<br/>Henkel компаниясы бөгде әзірлеушілердің cookie файлдарын бақыламайды және олар*/}
                {/*<br/>үшін жауап бермейді. Қосымша ақпаратты Сіз әзірлеуші сайтынан таба аласыз.*/}
            </div>
            <Footer/>
        </div>
    )
}
