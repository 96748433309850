import f1 from '../../lang/ru/1.webp';
import f2 from '../../lang/ru/2.webp';
import f3 from '../../lang/ru/3.webp';
import f4 from '../../lang/ru/4.webp';
import f5 from '../../lang/ru/5.webp';
import f6 from '../../lang/ru/6.webp';
import f7 from '../../lang/ru/7.webp';
import f8 from '../../lang/ru/8.webp';
import f9 from '../../lang/ru/9.webp';
import f10 from '../../lang/ru/10.webp';
import f11 from '../../lang/ru/11.webp';
import f12 from '../../lang/ru/12.webp';
import f13 from '../../lang/ru/13.webp';
import f14 from '../../lang/ru/14.webp';
import f15 from '../../lang/ru/15.webp';
import f16 from '../../lang/ru/16.webp';
import f17 from '../../lang/ru/17.webp';
import f18 from '../../lang/ru/18.webp';
import f19 from '../../lang/ru/19.webp';
import f20 from '../../lang/ru/20.webp';
import f21 from '../../lang/ru/21.webp';
import f22 from '../../lang/ru/22.webp';
import f23 from '../../lang/ru/23.webp';
import {ReactComponent as Picture} from '../../assets/picture.svg';
import {ReactComponent as Video} from '../../assets/video.svg';
import {ReactComponent as ArrowClose} from '../../assets/ArrowClose.svg';
import VideoContent from '../../lang/ru/video.mp4';
import {Card} from "./Card";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {useRef, useState} from "react";
import { CSSTransition } from "react-transition-group";
import {Navigation, A11y} from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import {ReactComponent as Close} from '../../assets/Close.svg';
import {ReactComponent as CloseViolet} from '../../assets/CloseViolet.svg';
import {ReactComponent as Airba} from '../../assets/Airba.svg';
import {ReactComponent as Arbuz} from '../../assets/Arbuz.svg';
import {ReactComponent as Cosmart} from '../../assets/Cosmart.svg';
import {ReactComponent as Magnum} from '../../assets/Magnum.svg';
import 'swiper/css';
import inst1 from '../../lang/ru/inst/1.webp';
import inst2 from '../../lang/ru/inst/2.webp';
import inst3 from '../../lang/ru/inst/3.webp';
import inst4 from '../../lang/ru/inst/4.webp';
import inst5 from '../../lang/ru/inst/5.webp';
import inst6 from '../../lang/ru/inst/6.webp';
import inst7 from '../../lang/ru/inst/7.webp';
import inst8 from '../../lang/ru/inst/8.webp';
import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Content = () => {
    const [filter, setFilter] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showInstModal, setShowInstModal] = useState(false);
    const [showTryModal, setShowTryModal] = useState(false);
    const [shopLinks, setShopLinks] = useState({});
    const content = {
        "cards": [
            {
                "label": "Черный",
                "key": "1-0",
                "image": f1,
                "link": "https://sk-qr.com/de/en?pid=E6A6A6B6-3719-40D3-97BB-3F94BA75FE76&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_1_0_black&_gl=1*13tvdon*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257285-kraska_palette_icc_n1_chernyi_110_ml?ref=catalog#/",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-n1-chernyi-100-ml-100189573/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }
            },
            {
                "label": "иссиня-ЧЕРНЫЙ",
                "key": "1-1",
                "image": f2,
                "link": "https://sk-qr.com/de/en?pid=729A9AEF-C7F1-40C9-8B32-BA4FCEA20BC1&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_1_1_blueblack&_gl=1*gotybs*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257300-kraska_palette_icc_c1_dlya_volos_issinya_chernyi_110_ml?ref=catalog#/",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-3h-uhod-krem-kraska-c1-chernyi-100-ml-101069137/",
                    "magnum": "https://magnum.page.link/wnvZ"
                }
            },
            {
                "label": "темно-каштановый",
                "key": "3-0",
                "image": f3,
                "link": "https://sk-qr.com/de/en?pid=AC0E6F27-AEC2-4FB5-B115-2FF0B32D6F3B&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_3_0_darkbrown&_gl=1*1h7rkid*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257289-kraska_palette_icc_n2_temno_kashtanovyi_110_ml?ref=catalog#/",
                    "kaspi": "https://kaspi.kz/shop/p/palette-intensivnyi-tsvet-krem-kraska-n2-temno-kashtanovyi-100-ml-100189574/",
                    "magnum": "https://magnum.page.link/5voH"
                }
            },
            {
                "label": "темный-ШОКОЛАД",
                "key": "3-65",
                "image": f4,
                "link": "https://sk-qr.com/de/en?pid=281F35D6-602B-454F-A640-824CF4A3C84C&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_3_65_darkchocolate&_gl=1*1p0x26n*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257309-kraska_palette_icc_w2_dlya_volos_temnyi_shokolad_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-w2-shokoladnyi-100-ml-100189571/",
                    "magnum": "https://magnum.page.link/PnvB"
                }
            },
            {
                "label": "Красное дерево",
                "key": "3-68",
                "image": f5,
                "link": "https://sk-qr.com/de/en?pid=0D89542C-520F-42AD-B6AF-DB720FD95DBF&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_3_68_darkmahogany&_gl=1*1p0x26n*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257305-kraska_palette_icc_r2_dlya_volos_krasnoe_derevo_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-r2-krasnyi-100-ml-100189579/",
                    "magnum": "https://magnum.page.link/VSHi"
                }
            },
            {
                "label": "КАШТАНОВЫЙ",
                "key": "4-0",
                "image": f6,
                "link": "https://sk-qr.com/de/en?pid=35563AF6-1BC6-4419-BBE8-D13273CB705B&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_4_0_middlebrown&_gl=1*1p0x26n*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257292-kraska_palette_icc_n3_dlya_volos_kashtanovyi_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-n3-kashtanovyi-100-ml-100189575/",
                    "magnum": "https://magnum.page.link/zwcT"
                }
            },
            {
                "label": "Золотистый трюфель",
                "key": "4-5",
                "image": f7,
                "link": "https://sk-qr.com/de/en?pid=65099C76-FF2E-44FA-9D67-CBC15B45EB58&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_4_5_truffles&_gl=1*1p0x26n*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/288141-kraska_palette_icc_g3_zolotistyi_tryufel_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-intensivnyi-tsvet-krem-kraska-4-5-zolotoi-110-ml-108030205/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }
            },
            {
                "label": "Золотистый кофе",
                "key": "4-60",
                "image": f8,
                "link": "https://sk-qr.com/de/en?pid=56F6B79E-0C3E-4872-B23F-07ECB500AC60&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_4_60_lustrousmiddlebrown&_gl=1*1h7rkid*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257311-kraska_palette_icc_wn3_dlya_volos_zolotistyi_kofe_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-wn3-korichnevyi-100-ml-100189583/",
                    "magnum": "https://magnum.page.link/QoFu"
                }
            },
            {
                "label": "КРАСНЫЙ ГРАНАТ",
                "key": "4-88",
                "image": f9,
                "link": "https://sk-qr.com/de/en?pid=A652F0FC-4FBD-4CA3-B521-E65B8657251C&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_4_88_intensivedarkred&_gl=1*1lhgio0*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/288140-kraska_palette_icc_rf3_krasnyi_granat_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-rf3-krasnyi-110-ml-102768151/",
                    "magnum": "https://magnum.page.link/DeXQ"
                }
            },
            {
                "label": "Баклажан",
                "key": "4-89",
                "image": f10,
                "link": "https://sk-qr.com/de/en?pid=BB620B2D-1058-415D-B1E1-2F071BD8A06A&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_4_89_intensiveaubergine&_gl=1*1lhgio0*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257307-kraska_palette_icc_ref3_dlya_volos_baklazhan_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-pfe3-fioletovyi-100-ml-100212633/",
                    "magnum": "https://magnum.page.link/w7Ke"
                }
            },
            {
                "label": "светло каштановый",
                "key": "5-0",
                "image": f11,
                "link": "https://sk-qr.com/de/en?pid=4C44791F-1B32-4D49-B1D6-415BBF3CE5E7&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_5_0_lightbrown",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/cat/224409-uhod_za_volosami#/?%5B%7B%22name%22%3A%22Palette%22,%22slug%22%3A%22brands%22,%22type%22%3A%22check%22,%22component%22%3A%22filters%22%7D%5D",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-n1-chernyi-100-ml-100189573/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }
            },
            {
                "label": "КАШТАН",
                "key": "5-68",
                "image": f12,
                "link": "https://sk-qr.com/de/en?pid=2ACD4799-5F10-4790-A6ED-E364981B89EB&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_5_68_middlechestnut&_gl=1*hc8xoe*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/288155-kraska_palette_icc_r4_kashtan_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-r4-kashtanovyi-100-ml-100189580/",
                    "magnum": "https://magnum.page.link/zma9"
                }
            },
            {
                "label": "ТЕМНО-РУСЫЙ",
                "key": "6-0",
                "image": f13,
                "link": "https://sk-qr.com/de/en?pid=0E550B31-25DE-445F-B231-573E944636F3&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_6_0_darkblonde&_gl=1*p1nwro*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MDc1NS4yMC4wLjE3MTQ2NTA3NTUuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/cat/224409-uhod_za_volosami#/?%5B%7B%22name%22%3A%22Palette%22,%22slug%22%3A%22brands%22,%22type%22%3A%22check%22,%22component%22%3A%22filters%22%7D%5D",
                    "kaspi": "https://kaspi.kz/shop/p/palette-krem-kraska-6-0-n5-temno-rusyi-110-ml-100189576/",
                    "magnum": "https://magnum.page.link/qdh5"
                }
            },
            {
                "label": "пепельно темно русый",
                "key": "6-1",
                "image": f14,
                "link": "https://sk-qr.com/de/en?pid=77730BB5-BD06-4134-9FCF-E8DC5B32DB90&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_6_1_ashydarkblonde",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/cat/224409-uhod_za_volosami#/?%5B%7B%22name%22%3A%22Palette%22,%22slug%22%3A%22brands%22,%22type%22%3A%22check%22,%22component%22%3A%22filters%22%7D%5D",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-n1-chernyi-100-ml-100189573/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }
            },
            {
                "label": "Нуга",
                "key": "6-65",
                "image": f15,
                "link": "https://sk-qr.com/de/en?pid=27E31058-77B0-4927-A705-17DFFDB28F65&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_6_65_nougat",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/cat/224409-uhod_za_volosami#/?%5B%7B%22name%22%3A%22Palette%22,%22slug%22%3A%22brands%22,%22type%22%3A%22check%22,%22component%22%3A%22filters%22%7D%5D",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-n1-chernyi-100-ml-100189573/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }
            },
            {
                "label": "Марсала",
                "key": "6-80",
                "image": f16,
                "link": "https://sk-qr.com/de/en?pid=17792456-B85D-4B86-8169-11B333CF8C8C&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_6_80_marsalabrown&_gl=1*xjbkg9*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MzE0Ni4yMS4wLjE3MTQ2NTMxNDYuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/288158-kraska_palette_icc_rn5_marsala_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-n1-chernyi-100-ml-100189573/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }
            },
            {
                "label": "Средне русый",
                "key": "7-0",
                "image": f17,
                "link": "https://sk-qr.com/de/en?pid=C0695AE2-9E72-4078-94A2-1D67DA46DE9E&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_7_0_middleblonde&_gl=1*xjbkg9*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MzE0Ni4yMS4wLjE3MTQ2NTMxNDYuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257295-kraska_palette_icc_n6_dlya_volos_sredne_rusyi_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-3d-uhod-krem-kraska-7-0-n6-sredne-rusyi-rusyi-110-ml-100189849/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }
            },
            {
                "label": "Холодный средне русый",
                "key": "7-1",
                "image": f18,
                "link": "https://sk-qr.com/de/en?pid=5E882A3D-8104-431D-9137-A58B1B753E26&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_7_1_coolmiddleblonde&_gl=1*1mz9jxj*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MzE0Ni4yMS4wLjE3MTQ2NTMxNDYuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257302-kraska_palette_icc_c6_dlya_volos_holodnyi_sredne_rusyi_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-3d-uhod-krem-kraska-c6-7-1-holodnyi-sredne-rusyi-rusyi-110-ml-100189570/",
                    "magnum": "https://magnum.page.link/rKuK"
                }
            },
            {
                "label": "холодный Русый",
                "key": "7-2",
                "image": f19,
                "link": "https://sk-qr.com/de/en?pid=78DB275F-B911-40B7-9032-DF226D84AC2B&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_7_2_sunnymiddleblonde&_gl=1*1d0o7b1*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MzE0Ni4yMS4wLjE3MTQ2NTMxNDYuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/cat/224409-uhod_za_volosami#/?%5B%7B%22name%22%3A%22Palette%22,%22slug%22%3A%22brands%22,%22type%22%3A%22check%22,%22component%22%3A%22filters%22%7D%5D",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-3h-uhod-krem-kraska-7-2-rusyi-110-ml-100189853/",
                    "magnum": "https://magnum.page.link/Y6jX"
                }
            },
            {
                "label": "Роскошный медный",
                "key": "7-77",
                "image": f20,
                "link": "https://sk-qr.com/de/en?pid=6A46154E-1D73-4819-B1AD-1FB32A247DFA&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_7_77_intensivecopper&_gl=1*1hcv088*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MzE0Ni4yMS4wLjE3MTQ2NTMxNDYuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/cat/224409-uhod_za_volosami#/?%5B%7B%22name%22%3A%22Palette%22,%22slug%22%3A%22brands%22,%22type%22%3A%22check%22,%22component%22%3A%22filters%22%7D%5D",
                    "kaspi": "https://kaspi.kz/shop/p/schwarzkopf-palette-krem-kraska-kr7-7-77-roskoshnyi-mednyi-mednyi-110-ml-109198463/",
                    "magnum": "https://magnum.page.link/FUQQ"
                }
            },
            {
                "label": "Русый",
                "key": "8-0",
                "image": f21,
                "link": "https://sk-qr.com/de/en?pid=CEA667B1-AE54-4ACD-985D-3B1839695764&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_8_0_lightblonde&_gl=1*1hcv088*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MzE0Ni4yMS4wLjE3MTQ2NTMxNDYuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/item/257296-kraska_palette_icc_n7_dlya_volos_rusyi_110_ml?ref=catalog",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-n7-rusyi-100-ml-100189577/",
                    "magnum": "https://magnum.page.link/xZSf"
                }
            },
            {
                "label": "Пепельно светло русый",
                "key": "8-1",
                "image": f22,
                "link": "https://sk-qr.com/de/en?pid=A793C1C3-B148-444C-9CAA-B2EE33C74846&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_8_1_lightblondecendre",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/cat/224409-uhod_za_volosami#/?%5B%7B%22name%22%3A%22Palette%22,%22slug%22%3A%22brands%22,%22type%22%3A%22check%22,%22component%22%3A%22filters%22%7D%5D",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-n1-chernyi-100-ml-100189573/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }
            },
            {
                "label": "пудровый блонд",
                "key": "10-46",
                "image": f23,
                "link": "https://sk-qr.com/de/en?pid=5F49BCD3-A04D-4E9A-86CA-EB7E467FC237&utm_source=qr&utm_medium=offline&utm_campaign=skint_paletteicc_pos&utm_content=picc_10_46_p&_gl=1*1yfof9q*_ga*MTQyMDI4NjUyMi4xNzExNTMxNzk3*_ga_DGC9YT3Y9M*MTcxNDY1MzE0Ni4yMS4wLjE3MTQ2NTMxNDYuNjAuMC4w",
                "shop_links": {
                    "arbuz": "https://arbuz.kz/ru/almaty/catalog/cat/224409-uhod_za_volosami#/?%5B%7B%22name%22%3A%22Palette%22,%22slug%22%3A%22brands%22,%22type%22%3A%22check%22,%22component%22%3A%22filters%22%7D%5D",
                    "kaspi": "https://kaspi.kz/shop/p/palette-stoikaja-krem-kraska-krem-kraska-bw10-blondin-100-ml-100212711/",
                    "magnum": "https://magnum.page.link/6uHZ"
                }

            }
        ],
        "instruction": [
            {
                "photo": inst1,
                "label": "СОДЕРЖИМОЕ УПАКОВКИ",
                "p1": "1. Тюбик с окрашивающим кремом",
                "p2": "2. Флакон с проявляющей эмульсией",
                "p3": "3. Маска-уход для волос",
                "p4": "4. Пара перчаток",
                "p5": "5. Инструкция по применению",
            },
            {
                "photo": inst2,
                "label": null,
                "p1": "ШАГ 1: Наденьте перчатки",
                "p2": "ШАГ 2: Добавьте в чашу 1 и 2",
                "p3": "ШАГ 3: Тщательно перемешивайте",
                "p4": null,
                "p5": null
            },
            {
                "photo": inst3,
                "label": null,
                "p1": "ШАГ 4: Нанесите на сухие волосы щеткой",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst4,
                "label": null,
                "p1": "ШАГ 5: Вариант 1\n" +
                    "Нанесите на сухие волосы щеткой",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst5,
                "label": null,
                "p1": "ШАГ 5: Вариант 2.1\n" +
                    "Нанесение на отросшие корни",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst6,
                "label": null,
                "p1": "ШАГ 5: Вариант 2.2\n" +
                    "Нанесение по длине волос",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst7,
                "label": null,
                "p1": "ШАГ 6: Нанесите ухаживающий кондиционер на влажные волосы и смойте через 2 минуты",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst8,
                "label": null,
                "p1": "ШАГ 6: Смойте через 30 минут",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            }
        ],
        "video": VideoContent
    }
    const options = [
        {
            "label": "Все оттенки", "value": [],
        },
        {
            "label": "Черные", "value": ["1-0", "1-1"],
        },
        {
            "label": "Красные, фиолетовые", "value": ["3-68", "4-88", "4-89", "7-77","6-65", "6-80"],
        },
        {
            "label": "Блонд, осветлитель", "value": ["10-46"],
        },
        {
            "label": "Каштановые", "value": ["3-0", "3-65", "4-0", "4-5", "4-60", "5-68", "5-0"],
        },
        {
            "label": "Русые", "value": ["6-0", "6-1", "7-0", "7-1", "7-2", "8-0", "8-1"],
        }
    ];
    return(
        <div className={"content"} style={{minHeight:"calc(100vh - 220px)"}}>

            <Helmet><title>Вы готовы к переменам? Нажмите на упаковку, чтобы подобрать свой цвет.</title></Helmet>
            <h2>Вы готовы к переменам?<br/> Нажмите на упаковку, чтобы подобрать свой цвет.</h2>
            <div style={{marginTop:30, marginBottom:30, width:"100%", maxWidth:390}}>
                <Dropdown onChange={(option)=>{setFilter(option.value)}} controlClassName={"dropdown-control"} menuClassName={"dropdown-menu"}
                          arrowClosed={<span className="arrow-closed"><ArrowClose/></span>}
                          arrowOpen={<span className="arrow-open"><ArrowClose/></span>}
                          options={options} placeholder="Все оттенки" />
            </div>
                <div className={"wrapper"}>
                    {
                        content.cards.map((item, index) => {
                            if (filter.length === 0){
                                return(
                                    <Card
                                        setShopLinks={setShopLinks}
                                        setShowTryModal={setShowTryModal}
                                        key={index} item={item}/>
                                )
                            }else{
                                if (filter.includes(item.key)){
                                    return (
                                        (
                                            <Card
                                                setShopLinks={setShopLinks}
                                                setShowTryModal={setShowTryModal}
                                                key={index} item={item} />
                                        )
                                    )
                                }
                            }
                        })
                    }
                </div>
                <div className={"button-block"}>
                    <button onClick={()=>{setShowModal(true)}}><Video/> <p>Посмотреть видео <br/> Как окрасить волосы в домашних условиях</p></button>
                    <button onClick={()=>{setShowInstModal(true)}}><Picture/> <p>Посмотреть инструкцию <br/> Как окрасить волосы в домашних условиях</p></button>
                </div>
                <div className={"mobile-button-block"}>
                    <button onClick={()=>{setShowModal(true); console.log("123");}}><Video/> <p>Видео</p></button>
                    <button onClick={()=>{setShowInstModal(true)}}><Picture/> <p>Инструкция</p></button>
                </div>
            <div style={{position:"relative"}}>
                <CSSTransition
                    mountOnEnter
                    unmountOnExit
                    in={showModal}
                    timeout={{ enter: 700, exit: 700 }}
                    classNames="modal"
                >
                    <div className={"modal"}>
                        <div style={{ width: "calc(100% - 102px)", maxWidth:1000, maxHeight:730, position:"relative", background:"#6B1A69", padding:20}}>
                            <div onClick={()=>{setShowModal(false); setCurrentIndex(1)}} style={{position:"absolute", width:24, height:24, top:-30, right:-25, zIndex:101}}>
                                <Close/>
                            </div>
                            <video width={"100%"} height={"100%"} controls controlsList={"nodownload"}>
                                <source src={VideoContent} type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </CSSTransition>
            </div>

            <div style={{position:"relative"}}>
                <CSSTransition
                    mountOnEnter
                    unmountOnExit
                    in={showInstModal}
                    timeout={{ enter: 700, exit: 700 }}
                    classNames="modal"
                >
                    <div className={"modal"}>
                        <div style={{display:"flex", justifyContent:"center", padding:20, boxSizing:"border-box", flexDirection:"column", height:"calc(100% - 100px)", alignItems:"center"}}>
                            <div className={"inst"}>
                                <div className={"inst-header"} onClick={()=>{setShowInstModal(false); setCurrentIndex(1)}}>
                                    <p style={{padding:0, margin:0, fontWeight:700, color:"white", fontSize:20}}>Инструкция</p>
                                    <Close/>
                                </div>
                                <div className={"inst-desktop"} onClick={()=>{setShowInstModal(false); setCurrentIndex(1)}}>
                                    <Close/>
                                </div>
                                <div className={"swiper-appear-desktop"}>
                                    {
                                        content.instruction.map((item, index) => (
                                            <div style={{maxWidth:180, minWidth:200, width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                <img draggable={false} src={item.photo} style={{objectFit:"contain"}}/>
                                                <div style={{color:"white"}}>
                                                    {item.label && <p style={{fontSize:12}}>{item.label}</p>}
                                                    {item.p1 && <p style={{fontSize:10}}>{item.p1}</p>}
                                                    {item.p2 && <p style={{fontSize:10}}>{item.p2}</p>}
                                                    {item.p3 && <p style={{fontSize:10}}>{item.p3}</p>}
                                                    {item.p4 && <p style={{fontSize:10}}>{item.p4}</p>}
                                                    {item.p5 && <p style={{fontSize:10}}>{item.p5}</p>}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={"swiper-appear"} style={{position:"relative"}}>
                                    <Swiper
                                        navigation={{
                                            prevEl: ".swiper-button-prev",
                                            nextEl: ".swiper-button-next",
                                        }}
                                        modules={[Navigation, A11y]}
                                        onSlideChange={(swiper)=> {
                                            setCurrentIndex(swiper.activeIndex + 1)
                                        }}
                                        style={{width:"100%", maxWidth:1400}}
                                        breakpoints={{
                                            1025: {
                                                slidesPerView: 3,
                                                centeredSlides: false,
                                                centeredSlidesBounds:false
                                            },
                                        }}
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        centeredSlides={true}
                                        centeredSlidesBounds={true}
                                    >
                                        {
                                            content.instruction.map((item, index) => (
                                                <SwiperSlide
                                                    className={"swiper-slide-div"}
                                                    key={index}>
                                                    <div style={{marginTop:1, width:"100%", display:"flex", justifyContent:"center"}}>
                                                        <img draggable={false} src={item.photo} style={{width:"100%"}}/>
                                                    </div>
                                                    <div style={{color:"white"}}>
                                                        {item.label && <p>{item.label}</p>}
                                                        {item.p1 && <p style={{fontSize:14}}>{item.p1}</p>}
                                                        {item.p2 && <p style={{fontSize:14}}>{item.p2}</p>}
                                                        {item.p3 && <p style={{fontSize:14}}>{item.p3}</p>}
                                                        {item.p4 && <p style={{fontSize:14}}>{item.p4}</p>}
                                                        {item.p5 && <p style={{fontSize:14}}>{item.p5}</p>}
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                            </div>
                            <div className={"swiper-button-block"} style={{position:"relative", zIndex:100}}>
                                <button className="swiper-button-prev" style={currentIndex === 1 ? {background:"rgba(134, 134, 134, 1)"} : {textAlign:"center"}}><p style={{margin:0, width:"100%", textAlign:"center"}}>Назад</p></button>
                                <button className="swiper-button-next" style={currentIndex === content.instruction.length ? {background:"rgba(134, 134, 134, 1)"} : {textAlign:"center"}}><p style={{margin:0, width:"100%", textAlign:"center"}}>Вперед</p></button>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
                <div style={{position:"relative"}}>
                    <CSSTransition
                        mountOnEnter
                        unmountOnExit
                        in={showTryModal}
                        timeout={{ enter: 700, exit: 700 }}
                        classNames="modal"
                    >
                        <div className={"modal"}>
                            <div style={{width:"100%", height:"100%", paddingRight:4}}>
                                <div className={"modal-buy"}>
                                    <div style={{display:"flex", justifyContent:"space-between", width:"100%", paddingLeft:16, paddingRight:16, boxSizing:"border-box", alignItems:"center", fontSize:20, fontWeight:600}}><p>Купить</p>
                                        <button onClick={() => {setShowTryModal(false)}} style={{background: "transparent", width:"30px", padding:0}}><CloseViolet/></button>
                                    </div>
                                    <div style={{display:"flex", flexDirection:"column", gap:24, paddingTop:24}}>
                                        <div style={{paddingLeft:16, paddingRight:16, display:"flex", flexDirection:"row", gap:16}}>
                                            <a href={shopLinks.arbuz} style={{width:"100%", height:100, background:"#FFFFFF", borderRadius:16, display:"flex", justifyContent:"center", alignItems:"center"}}><Arbuz/></a>
                                            <a href={shopLinks.kaspi} style={{width:"100%", height:100, background:"#FFFFFF", borderRadius:16, display:"flex", justifyContent:"center", alignItems:"center"}}><Airba/></a>
                                        </div>
                                        <div className={"magnum-link"} >
                                            <a href={shopLinks.magnum} style={{width:"100%", height:100, background:"#FF0D73", borderRadius:16, display:"flex", justifyContent:"center", alignItems:"center"}}><Magnum/></a>
                                            <div style={{width:"100%", height:100, background:"#FF0D73", borderRadius:16, display:"flex", justifyContent:"center", alignItems:"center", opacity:0}}><Cosmart/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
