import React from 'react';
import {Route, Routes} from 'react-router';
import {Content} from "./components/ru/Content";
import {Rights} from "./components/ru/Rights";
import {Internal} from "./components/ru/Internal";
import {Cookie} from "./components/ru/Cookie";
import {Privacy} from "./components/ru/Privacy";
import {Personal} from "./components/ru/Personal";
import {KZContent} from "./components/kz/Content";
import {KZRights} from "./components/kz/Rights";
import {KZInternal} from "./components/kz/Internal";
import {KZCookie} from "./components/kz/Cookie";
import {KZPrivacy} from "./components/kz/Privacy";
import {KZPersonal} from "./components/kz/Personal";
import {UZContent} from "./components/uz/Content";
import {UZRights} from "./components/uz/Rights";
import {UZInternal} from "./components/uz/Internal";
import {UZCookie} from "./components/uz/Cookie";
import {UZPrivacy} from "./components/uz/Privacy";
import {UZPersonal} from "./components/uz/Personal";
import {GEContent} from "./components/ge/Content";
import {GERights} from "./components/ge/Rights";
import {GECookie} from "./components/ge/Cookie";
import {GEInternal} from "./components/ge/Internal";
import {GEPersonal} from "./components/ge/Personal";
import {GEPrivacy} from "./components/ge/Privacy";

export const useRoutes = () => {
    return (
        <Routes>
                <Route path="/" element={<Content/>} />
                <Route path="/ru/" element={<Content/>} />
                <Route path="/ru/rights" element={<Rights/>} />
                <Route path="/ru/cookie" element={<Cookie/>} />
                <Route path="/ru/internal" element={<Internal/>} />
                <Route path="/ru/personal" element={<Personal/>} />
                <Route path="/ru/privacy" element={<Privacy/>} />
                <Route path="/kz" element={<KZContent/>} />
                <Route path="/kz/rights" element={<KZRights/>} />
                <Route path="/kz/cookie" element={<KZCookie/>} />
                <Route path="/kz/internal" element={<KZInternal/>} />
                <Route path="/kz/personal" element={<KZPersonal/>} />
                <Route path="/kz/privacy" element={<KZPrivacy/>} />
                <Route path="/uz" element={<UZContent/>} />
                <Route path="/uz/rights" element={<UZRights/>} />
                <Route path="/uz/cookie" element={<UZCookie/>} />
                <Route path="/uz/internal" element={<UZInternal/>} />
                <Route path="/uz/personal" element={<UZPersonal/>} />
                <Route path="/uz/privacy" element={<UZPrivacy/>} />
                <Route path="/ge" element={<GEContent/>} />
                <Route path="/ge/rights" element={<GERights/>} />
                <Route path="/ge/cookie" element={<GECookie/>} />
                <Route path="/ge/internal" element={<GEInternal/>} />
                <Route path="/ge/personal" element={<GEPersonal/>} />
                <Route path="/ge/privacy" element={<GEPrivacy/>} />
        </Routes>
    );
};
