import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const UZPersonal = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Shaxsiy ma'lumotlarni qayta ishlashga</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Shaxsiy ma'lumotlarni qayta ishlashga rozilik</h2>
                <br/>Saytda ro’yxatdan o’tish orqali foydalanuvchi «Henkel Central Asia & Caucasus» («Хенкель
                <br/>Сентрал Эйша энд Коукасас») MChJ, keyin «mijoz» (bin 060940000796, 050012, Qozog’iston
                <br/>Respublikasi, Olmaota shahri, masanchi ko’chasi, 78) shaxsiy ma’lumotlarini qayta ishlashga
                <br/>roziligini tasdiqlaydi.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Maqsadlar:</h3>
                <br/>✔ buyurtmachining mahsulot iste’molchilari, xususan uning sifati haqida ma’lumot to’plash;
                <br/>✔ mijoz mahsulotlari haqida ma’lumot tarqatish;
                <br/>✔ ishtirokchilarni mijozning mahsulotlari, shuningdek, mijoz tomonidan o’tkaziladigan va
                <br/>(yoki) tashkil etiladigan tadbirlar to’g’risida xabardor qilish;
                <br/>✔ boshqa har qanday axborot-reklama xabarlarini yuborish;
                <br/>✔ va marketing tahlilini o’tkazish va statistik ma’lumotlarni tayyorlash uchun xabarlar.
                <br/><br/>Iste’molchi «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») MChJ,
                <br/>shu jumladan uning nomidan ish olib boradigan shaxslar, shu jumladan yig’ish, yozish,
                <br/>tizimlashtirish, to’plash, saqlash, tozalash (yangilash, o’zgartirish), foydalanish, uzatish (berish,
                <br/>kirish), transchegaraviy uzatish quyidagi shaxsiy ma’lumotlar:
                <br/><br/>• Ism
                <br/>• Familiya
                <br/>• E-pochta manzili)
                <br/>• Uyali telefon
                <br/><br/>Mijozning roziligi mijoz tomonidan «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша
                <br/>энд Коукасас») MChJga shaxsiy ma’lumotlarni qayta ishlash bo’yicha yozma ariza yuborish
                <br/>orqali bekor qilingan sanaga qadar amal qiladi.
            </div>
            <Footer/>
        </div>
    )
}
