import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const KZCookie = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Cookie Файлдары</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Cookie Файлдары</h2>
                <br/>Біз cookie файлдарын сайты пайдалану кезінде Сізге көбірек мүмкіндіктер беру үшін
                <br/>қолданамыз.
                <br/><br/>Cookie файлдары бұл біздің сайт бойынша навигация үшін қажетті, браузеріңіз
                <br/>компьютеріңіздің қатты дискісіне уақытша сақтауға орналастыратын деректердің
                <br/>шағын блоктары болып табылады.
                <br/><br/>Cookie файлдары Сіз туралы жеке ақпаратты қамтымайды және жеке пайдаланушыны
                <br/>сәйкестендіру үшін пайдаланылмайды.
                <br/><br/>Cookie файлында көбінесе Сіздің құрылғыңызда сақталатын анонимді нөмір (кездейсоқ
                <br/>пайда болатын) болып табылатын бірегей идентификатор болады. Кейбір файлдар
                <br/>сайттағы жұмыс сеансының соңында жойылады; басқалары Сіздің компьютеріңізде
                <br/>ұзақ сақталады.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Cookies файлдарының түрлері</h2>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Жеке cookie файлдары</h2>
                <br/>Жеке cookie файлдары сіз кіретін сайтпен орнатылады және тек осы сайтпен оқылуы
                <br/>мүмкін.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Бөгде әзірлеушілердің cookie файлдары</h2>
                <br/>Бөгде әзірлеушілердің cookie файлдары сервистерін біз пайдаланатын басқа
                <br/>ұйымдармен орнатылады. Мысалы, біз бөгде аналитикалық сервистерді пайдаланамыз
                <br/>және осы сервистердің провайдерлері біздің сайттағы бөлімдердің қайсысы танымал
                <br/>және қайсысы танымал емес екендігі туралы хабарлау үшін біздің атымыздан cookie
                <br/>файлдарын орнатады. Сіз кірген сайтта, мәселен, YouTube-тен жүктелген материалдар
                <br/>болуы мүмкін және бұл өз сайттар cookie файлдарын орната алады.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Неліктен Henkel cookie файлдарын пайдаланады?</h2>
                <br/>Cookie файлдарындағы ақпарат сервистердің жұмысын жақсарту үшін пайдаланылады,
                <br/>мысалы:
                <br/><br/>• қызметке Сіздің құрылғыңызды тануға мүмкіндік береді, сондықтан бір
                <br/>тапсырма үшін немесе сессия барысында бірдей ақпаратты бірнеше рет
                <br/>енгізудің, мысалы, web-нысанды немесе сауалнаманы толтырудың қажеті жоқ
                <br/>• «Менің контентім» функциясын қолдау
                <br/>• бейне ойнатқыштың дұрыс жұмыс істеуін қамтамасыз ету
                <br/>• Сервисті пайдаланатын адамдардың санын анықтау – сервистерді пайдалануды
                <br/>жеңілдету және жылдам жұмысты қамтамасыз ету үшін жеткілікті өнімділікті
                <br/>қамсыздандыруға мүмкіндік береді; анонимді деректерді талдау – онлайн-
                <br/>сервистердің әртүрлі түрлерімен адамдардың қалай қарым-қатынас
                <br/>жасайтындығын түсінуге мүмкіндік береді. Осылайша біз оларды жақсарта
                <br/>аламыз.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Осы сайт пайдаланатын cookie файлдарының тізімі:</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>1. Google Analytics</h3>
                <br/>• heli-googleanalytics
                <br/>• __utmt_UA-XXX
                <br/>• __utma
                <br/>• __utmb
                <br/>• __utmc
                <br/>• __utmz
                <br/>Бұл cookie файлдары біздің сайтты пайдалану және оның мазмұны туралы ақпаратты
                <br/>жинау үшін қолданылады
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>2. Disable Google Analytics</h3>
                <br/>• ga-disable-UA-XXX
                <br/>Егер осы cookie файлы орнатылса, біздің сайты пайдалануыңыз туралы деректеріңіз
                <br/>жиналмайды
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>3. Youtube</h3>
                <br/>• VISITOR_INFO01_LIVE
                <br/>• YSC
                <br/>• GEUP
                <br/>• PREF
                <br/>Бұл cookie файлдары үшінші тараппен: «YouTube» бейне-порталымен қосылған.
                <br/>Ол біздің сайта бейнені пайдалану туралы ақпарат жинайды.
                <br/>Бұл cookie файлын орнату сайттағы бейнені пайдалануға БАЙЛАНЫСТЫ ЕМЕС.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>4. JSESSIONID</h3>
                <br/>• JSESSIONID
                <br/>Біздің байланыс формамыз «капчадан» тұрады. Бұл біздің формамызды теріс
                <br/>пайдаланудан қорғайтын адам ғана шеше алатын қарапайым міндет. Бұл cookie файлы
                <br/>Сіз шешуді сұраған «капчаны» анықтау үшін қолданылады.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>5. DoubleClick</h3>
                <br/>• id
                <br/>Google-ден DoubleClick сервисіне арналған сәйкестендіру нөмірін Сіздің браузеріңізге
                <br/>меншіктейді.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>6. Басқа</h3>
                <br/>Жоғарыда аталған cookie файлдарынан басқа, iFrames, JavaScript және т. б. сияқты
                <br/>технологиялардың көмегімен осы сайтқа енгізілген контентте (сыртқы) қосымша
                <br/>cookie файлдары, жеке немесе үшінші тарап әзірлеушілерінің cookie файлдары
                <br/>пайдаланылуы мүмкін.
                <br/>Cookie файлдарын пайдалануға рұқсат бере отырып, Сіз сондай контентте өз cookie
                <br/>файлдарын пайдалануға болатындығын мойындайсыз.
                <br/>Henkel компаниясы бөгде әзірлеушілердің cookie файлдарын бақыламайды және олар
                <br/>үшін жауап бермейді. Қосымша ақпаратты Сіз әзірлеуші сайтынан таба аласыз.
            </div>
            <Footer/>
        </div>
    )
}
