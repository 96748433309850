import f1 from '../../lang/kz/1.webp';
import f2 from '../../lang/kz/2.webp';
import f3 from '../../lang/kz/3.webp';
import f4 from '../../lang/kz/4.webp';
import f5 from '../../lang/kz/5.webp';
import f6 from '../../lang/kz/6.webp';
import f7 from '../../lang/kz/7.webp';
import f8 from '../../lang/kz/8.webp';
import f9 from '../../lang/kz/9.webp';
import f10 from '../../lang/kz/10.webp';
import f11 from '../../lang/kz/11.webp';
import f12 from '../../lang/kz/12.webp';
import f13 from '../../lang/kz/13.webp';
import f14 from '../../lang/kz/14.webp';
import f15 from '../../lang/kz/15.webp';
import f16 from '../../lang/kz/16.webp';
import f17 from '../../lang/kz/17.webp';
import f18 from '../../lang/kz/18.webp';
import f19 from '../../lang/kz/19.webp';
import f20 from '../../lang/kz/20.webp';
import f21 from '../../lang/kz/21.webp';
import f22 from '../../lang/kz/22.webp';
import f23 from '../../lang/kz/23.webp';
import {ReactComponent as Picture} from '../../assets/picture.svg';
import {ReactComponent as Video} from '../../assets/video.svg';
import {ReactComponent as ArrowClose} from '../../assets/ArrowClose.svg';
import VideoContent from '../../lang/ge/video.mp4';
import CleanHouse from '../../lang/ge/clean_house.png';
import {Card} from "./Card";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import {useRef, useState} from "react";
import { CSSTransition } from "react-transition-group";
import {Navigation, A11y} from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import {ReactComponent as Close} from '../../assets/Close.svg';
import {ReactComponent as CloseViolet} from '../../assets/CloseViolet.svg';
import {ReactComponent as Airba} from '../../assets/Airba.svg';
import {ReactComponent as Arbuz} from '../../assets/Arbuz.svg';
import {ReactComponent as Cosmart} from '../../assets/Cosmart.svg';
import {ReactComponent as Magnum} from '../../assets/Magnum.svg';
import 'swiper/css';

import inst1 from '../../lang/kz/inst/1.webp';
import inst2 from '../../lang/kz/inst/2.webp';
import inst3 from '../../lang/kz/inst/3.webp';
import inst4 from '../../lang/kz/inst/4.webp';
import inst5 from '../../lang/kz/inst/5.webp';
import inst6 from '../../lang/kz/inst/6.webp';
import inst7 from '../../lang/kz/inst/7.webp';
import inst8 from '../../lang/kz/inst/8.webp';
import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const GEContent = () => {
    const [filter, setFilter] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [showInstModal, setShowInstModal] = useState(false);
    const [showTryModal, setShowTryModal] = useState(false);
    const [shopLinks, setShopLinks] = useState({});
    const content = {
        "cards": [
            {
                "label": "Черный",
                "key": "1-0",
                "image": f1,
                "link": "https://sk-qr.com/PICC10/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/3774",
                }
            },
            {
                "label": "иссиня-ЧЕРНЫЙ",
                "key": "1-1",
                "image": f2,
                "link": "https://sk-qr.com/PICC11/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/products?search=palette",
                }
            },
            {
                "label": "темно-каштановый",
                "key": "3-0",
                "image": f3,
                "link": "https://sk-qr.com/PICC30/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7922",
                }
            },
            {
                "label": "темный-ШОКОЛАД",
                "key": "3-65",
                "image": f4,
                "link": "https://sk-qr.com/PICC365/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7910",
                }
            },
            {
                "label": "Красное дерево",
                "key": "3-68",
                "image": f5,
                "link": "https://sk-qr.com/PICC368/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/8025",
                }
            },
            {
                "label": "КАШТАНОВЫЙ",
                "key": "4-0",
                "image": f6,
                "link": "https://sk-qr.com/PICC40/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7923",
                }
            },
            {
                "label": "Золотистый трюфель",
                "key": "4-5",
                "image": f7,
                "link": "https://sk-qr.com/PICC45/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/products?search=palette",
                }
            },
            {
                "label": "Золотистый кофе",
                "key": "4-60",
                "image": f8,
                "link": "https://sk-qr.com/PICC460/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7911",
                }
            },
            {
                "label": "КРАСНЫЙ ГРАНАТ",
                "key": "4-88",
                "image": f9,
                "link": "https://sk-qr.com/PICC488/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/4661",
                }
            },
            {
                "label": "Баклажан",
                "key": "4-89",
                "image": f10,
                "link": "https://sk-qr.com/PICC489/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/8789",
                }
            },
            {
                "label": "светло каштановый",
                "key": "5-0",
                "image": f11,
                "link": "https://sk-qr.com/PICC50/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/products?search=palette",
                }
            },
            {
                "label": "КАШТАН",
                "key": "5-68",
                "image": f12,
                "link": "https://sk-qr.com/PICC568/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7812",
                }
            },
            {
                "label": "ТЕМНО-РУСЫЙ",
                "key": "6-0",
                "image": f13,
                "link": "https://sk-qr.com/PICC60/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7815",
                }
            },
            {
                "label": "пепельно темно русый",
                "key": "6-1",
                "image": f14,
                "link": "https://sk-qr.com/PICC61/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/products?search=palette",
                }
            },
            {
                "label": "Нуга",
                "key": "6-65",
                "image": f15,
                "link": "https://sk-qr.com/PICC665/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/products?search=palette",
                }
            },
            {
                "label": "Марсала",
                "key": "6-80",
                "image": f16,
                "link": "\thttps://sk-qr.com/PICC680/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/products?search=palette",
                }
            },
            {
                "label": "Средне русый",
                "key": "7-0",
                "image": f17,
                "link": "https://sk-qr.com/PICC70/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7814",
                }
            },
            {
                "label": "Холодный средне русый",
                "key": "7-1",
                "image": f18,
                "link": "https://sk-qr.com/PICC71/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7818",
                }
            },
            {
                "label": "холодный Русый",
                "key": "7-2",
                "image": f19,
                "link": "https://sk-qr.com/PICC72/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/3236",
                }
            },
            {
                "label": "Роскошный медный",
                "key": "7-77",
                "image": f20,
                "link": "https://sk-qr.com/PICC777/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7530",
                }
            },
            {
                "label": "Русый",
                "key": "8-0",
                "image": f21,
                "link": "https://sk-qr.com/PICC80/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/7813",
                }
            },
            {
                "label": "Пепельно светло русый",
                "key": "8-1",
                "image": f22,
                "link": "https://sk-qr.com/PICC81/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/products?search=palette",
                }
            },
            {
                "label": "пудровый блонд",
                "key": "10-46",
                "image": f23,
                "link": "https://sk-qr.com/PICC1046/KV",
                "shop_links": {
                    "clean_house": "https://ch.ge/product/8982",
                }
            }
        ],
        "instruction": [
            {
                "photo": inst1,
                "label": "პაკეტის შიგთავსი",
                "p1": "1. ტუბი საღებავით",
                "p2": "2. ემულსია",
                "p3": "3. თმის მოვლის კონდინციონერი",
                "p4": "4. ერთი წყვილი ხელთათმანი",
                "p5": "5. გამოყენების ინსტრუქცია",
            },
            {
                "photo": inst2,
                "label": null,
                "p1": "ნაბიჯი 1: გაიკეთეთ ხელთათმანი",
                "p2": "ნაბიჯი 2: დაამატეთ 1 და 2 თასში",
                "p3": "ნაბიჯი 3: საფუძვლიანად აურიეთ",
                "p4": null,
                "p5": null
            },
            {
                "photo": inst3,
                "label": null,
                "p1": "ნაბიჯი 4: მიღებული მასა, წაისვით მშრალ თმაზე ფუნჯით",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst4,
                "label": null,
                "p1": "და ასევე სიგრძეზე",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst5,
                "label": null,
                "p1": "ნაბიჯი 5.1: დაიტანეთ ის თმის სიგძეზე",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst6,
                "label": null,
                "p1": "ნაბიჯი 5.2: წაისვით თმის ძირებში +",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst7,
                "label": null,
                "p1": "ნაბიჯი 6: ჩამოიბანეთ 30 წუთის შემდეგ",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            },
            {
                "photo": inst8,
                "label": null,
                "p1": "ნაბიჟი 7: დაიტანეთ კონდინციონერი სველ თმაზე და ჩამოიბანეთ 2 წუთის შემდეგ",
                "p2": null,
                "p3": null,
                "p4": null,
                "p5": null
            }
        ],
        "video": VideoContent
    }
    const options = [
        {
            "label": "ყველა ფერი", "value": [],
        },
        {
            "label": "შავი", "value": ["1-0", "1-1"],
        },
        {
            "label": "წითელი, იასამნისფერი", "value": ["3-68", "4-88", "4-89", "7-77","6-65", "6-80"],
        },
        {
            "label": "ქერა, გამაღიავებელი", "value": ["10-46"],
        },
        {
            "label": "წაბლისფერი", "value": ["3-0", "3-65", "4-0", "4-5", "4-60", "5-68", "5-0"],
        },
        {
            "label": "მუქი ქერა", "value": ["6-0", "6-1", "7-0", "7-1", "7-2", "8-0", "8-1"],
        }
    ];

    return(
        <div className={"content"}>
            <Helmet><title>მზად ხართ ცვლილებებისთვის?
                გახსენი QR კოდი და შეარჩიე შენთვის
                სასურველი თმის ფერი.</title>
                <meta property="og:title" content="მზად ხართ ცვლილებებისთვის?
                გახსენი QR კოდი და შეარჩიე შენთვის
                სასურველი თმის ფერი." />
            </Helmet>

            <h2>მზად ხართ ცვლილებებისთვის?<br/> გახსენი QR კოდი და შეარჩიე შენთვის სასურველი თმის ფერი.</h2>
            <div style={{marginTop:30, marginBottom:30, width:"100%", maxWidth:390}}>
                <Dropdown onChange={(option)=>{setFilter(option.value)}} controlClassName={"dropdown-control"} menuClassName={"dropdown-menu"}
                          arrowClosed={<span className="arrow-closed"><ArrowClose/></span>}
                          arrowOpen={<span className="arrow-open"><ArrowClose/></span>}
                          options={options} placeholder="ყველა ფერი" />
            </div>
            <div className={"wrapper"}>
                {
                    content.cards.map((item, index) => {
                        if (filter.length === 0){
                            return(
                                <Card
                                    setShopLinks={setShopLinks}
                                    setShowTryModal={setShowTryModal}
                                    key={index} item={item}/>
                            )
                        }else{
                            if (filter.includes(item.key)){
                                return (
                                    (
                                        <Card
                                            setShopLinks={setShopLinks}
                                            setShowTryModal={setShowTryModal}
                                            key={index} item={item} />
                                    )
                                )
                            }
                        }
                    })
                }
            </div>
                <div className={"button-block"}>
                    <button onClick={()=>{setShowModal(true)}}><Video/> <p>ვიდეორგოლის საშუალებით, ნახავთ<br/> თუ როგორ შეგიძლიათ თმა შეიღებოთ სახლის პირობებში</p></button>
                    <button onClick={()=>{setShowInstModal(true)}}><Picture/> <p>ინსტრუქციის გახსნა - როგორ<br/> შევიღებოთ თმა სახლის პირობებში</p></button>
                </div>
                <div className={"mobile-button-block"}>
                    <button onClick={()=>{setShowModal(true); console.log("123");}}><Video/> <p>ვიდეო</p></button>
                    <button onClick={()=>{setShowInstModal(true)}}><Picture/> <p>ინსტრუქციები</p></button>
                </div>

            <div style={{position:"relative"}}>
                <CSSTransition
                    mountOnEnter
                    unmountOnExit
                    in={showModal}
                    timeout={{ enter: 700, exit: 700 }}
                    classNames="modal"
                >
                    <div className={"modal"}>
                        <div style={{ width: "calc(100% - 102px)", maxWidth:1000, maxHeight:730, position:"relative", background:"#6B1A69", padding:20}}>
                            <div onClick={()=>{setShowModal(false); setCurrentIndex(1)}} style={{position:"absolute", width:24, height:24, top:-30, right:-25, zIndex:101}}>
                                <Close/>
                            </div>
                            <video width={"100%"} height={"100%"} controls controlsList={"nodownload"}>
                                <source src={VideoContent} type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            <div style={{position:"relative"}}>
                <CSSTransition
                    mountOnEnter
                    unmountOnExit
                    in={showInstModal}
                    timeout={{ enter: 700, exit: 700 }}
                    classNames="modal"
                >
                    <div className={"modal"}>
                        <div style={{display:"flex", justifyContent:"center", padding:20, boxSizing:"border-box", flexDirection:"column", height:"calc(100% - 100px)", alignItems:"center"}}>
                            <div className={"inst"}>
                                <div className={"inst-header"} onClick={()=>{setShowInstModal(false); setCurrentIndex(1)}}>
                                    <p style={{padding:0, margin:0, fontWeight:700, color:"white", fontSize:20}}>გამოყენების ინსტრუქცია</p>
                                    <Close/>
                                </div>
                                <div className={"inst-desktop"} onClick={()=>{setShowInstModal(false); setCurrentIndex(1)}}>
                                    <Close/>
                                </div>
                                <div className={"swiper-appear-desktop"}>
                                    {
                                        content.instruction.map((item, index) => (
                                            <div style={{maxWidth:200, minWidth:200, width:"100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                <img draggable={false} src={item.photo} style={{objectFit:"contain"}}/>
                                                <div style={{color:"white"}}>
                                                    {item.label && <p style={{fontSize:12}}>{item.label}</p>}
                                                    {item.p1 && <p style={{fontSize:10}}>{item.p1}</p>}
                                                    {item.p2 && <p style={{fontSize:10}}>{item.p2}</p>}
                                                    {item.p3 && <p style={{fontSize:10}}>{item.p3}</p>}
                                                    {item.p4 && <p style={{fontSize:10}}>{item.p4}</p>}
                                                    {item.p5 && <p style={{fontSize:10}}>{item.p5}</p>}
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className={"swiper-appear"} style={{position:"relative"}}>
                                    <Swiper
                                        navigation={{
                                            prevEl: ".swiper-button-prev",
                                            nextEl: ".swiper-button-next",
                                        }}
                                        modules={[Navigation, A11y]}
                                        onSlideChange={(swiper)=> {
                                            setCurrentIndex(swiper.activeIndex + 1)
                                        }}
                                        style={{width:"100%", maxWidth:1400}}
                                        breakpoints={{
                                            1025: {
                                                slidesPerView: 3,
                                                centeredSlides: false,
                                                centeredSlidesBounds:false
                                            },
                                        }}
                                        spaceBetween={10}
                                        slidesPerView={1}
                                        centeredSlides={true}
                                        centeredSlidesBounds={true}
                                    >
                                            {
                                                content.instruction.map((item, index) => (
                                                    <SwiperSlide
                                                        className={"swiper-slide-div"}
                                                        key={index}>
                                                        <div style={{marginTop:1, width:"100%", display:"flex", justifyContent:"center"}}>
                                                            <img draggable={false} src={item.photo} style={{width:"100%"}}/>
                                                        </div>
                                                        <div style={{color:"white"}}>
                                                            {item.label && <p>{item.label}</p>}
                                                            {item.p1 && <p style={{fontSize:14}}>{item.p1}</p>}
                                                            {item.p2 && <p style={{fontSize:14}}>{item.p2}</p>}
                                                            {item.p3 && <p style={{fontSize:14}}>{item.p3}</p>}
                                                            {item.p4 && <p style={{fontSize:14}}>{item.p4}</p>}
                                                            {item.p5 && <p style={{fontSize:14}}>{item.p5}</p>}
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                            }
                                    </Swiper>
                                </div>
                            </div>
                            <div className={"swiper-button-block"} style={{position:"relative", zIndex:100}}>
                                <button className="swiper-button-prev" style={currentIndex === 1 ? {background:"rgba(134, 134, 134, 1)"} : {textAlign:"center"}}><p style={{margin:0, width:"100%", textAlign:"center"}}>უკან</p></button>
                                <button className="swiper-button-next" style={currentIndex === content.instruction.length ? {background:"rgba(134, 134, 134, 1)"} : {textAlign:"center"}}><p style={{margin:0, width:"100%", textAlign:"center"}}>წინ</p></button>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            <div style={{position:"relative"}}>
                <CSSTransition
                    mountOnEnter
                    unmountOnExit
                    in={showTryModal}
                    timeout={{ enter: 700, exit: 700 }}
                    classNames="modal"
                >
                    <div className={"modal"}>
                        <div style={{width:"100%", height:"100%", paddingRight:4}}>
                            <div className={"modal-buy"}>
                                <div style={{display:"flex", justifyContent:"space-between", width:"100%", paddingLeft:16, paddingRight:16, boxSizing:"border-box", alignItems:"center", fontSize:20, fontWeight:600}}><p>ყიდვა</p>
                                    <button onClick={() => {setShowTryModal(false)}} style={{background: "transparent", width:"30px", padding:0}}><CloseViolet/></button>
                                </div>
                                <div style={{display:"flex", flexDirection:"column", gap:24, paddingTop:24}}>
                                    <div style={{paddingLeft:16, paddingRight:16, display:"flex", flexDirection:"row", gap:16}}>
                                        <a href={shopLinks.clean_house} style={{width:"100%", height:100, background:"#FFFFFF", borderRadius:16, display:"flex", justifyContent:"center", alignItems:"center"}}><img style={{maxWidth:250}} src={CleanHouse}/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </div>
            <Footer/>
        </div>
    )
}
