import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const UZPrivacy = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Maxfiylik siyosati</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Maxfiylik siyosati</h2>
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>1. Umumiy qoidalar</h2>
                <br/>«Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») MChJ (bundan
                <br/>keyin «Henkel») va uning filiallari ushbu saytga tashrif buyurgan har qanday shaxsning maxfiy
                <br/>ma’lumotlarini hurmat qilishadi. Biz qanday ma’lumotlarni to’plashimiz va to’plangan
                <br/>ma’lumotlardan qanday foydalanishimiz haqida sizga ma’lumot berishni istaymiz. Bundan
                <br/>tashqari, to’plangan ma’lumotlarning to’g’riligini qanday tekshirish mumkinligini bilib olasiz va
                <br/>bizga bunday ma’lumotni olib tashlash haqida ko’rsatma berasiz. Ma’lumotlar shaxsiy
                <br/>ma’lumotlarni himoya qilish uchun mas’ul bo’lgan «Henkel» kompaniyasining tegishli filiali
                <br/>joylashgan davlatning amaldagi qonunchiligi talablariga muvofiq to’planadi, qayta ishlanadi va
                <br/>qat’iy qo’llaniladi. Biz amaldagi Qonunchilik talablariga muvofiqligini ta’minlash uchun
                <br/>qo’limizdan kelganini qilamiz.
                <br/><br/>Ushbu saytda to’plangan shaxsiy ma’lumotlar bilan barcha harakatlar Qozog’iston
                <br/>Respublikasining 2013 yil 21 maydagi 94-V-sonli «shaxsiy ma’lumotlar va ularni himoya qilish
                <br/>to’g’risida»gi qonuniga muvofiq amalga oshiriladi. Ushbu bayonot Henkel veb-sayti
                <br/>giperhavolalarni o’z ichiga olgan saytlarga taalluqli emas.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>2. Shaxsiy ma’lumotlarni to’plash, ulardan foydalanish va qayta ishlash</h2>
                <br/>Biz ma’lum shaxslarga tegishli ma’lumotlarni faqat axborotni qayta ishlash va undan foydalanish
                <br/>uchun to’playmiz, shu bilan birga aniq vazifalarni bajarish uchun faqat zarur va etarli
                <br/>ma’lumotlar to’planadi va agar siz ma’lumotni ixtiyoriy ravishda taqdim qilsangiz yoki undan
                <br/>foydalanishga rozilik bildirsangiz. Bizning saytimizga tashrif buyurganingizda, ma’lum
                <br/>ma’lumotlar avtomatik ravishda Henkel va/yoki uning filiallari serverlariga tizim boshqaruvi yoki
                <br/>statistik yoki zaxira maqsadlari uchun yoziladi. Yozib olingan ma’lumot sizning Internet-
                <br/>provayderingiz nomini, ba’zi hollarda sizning IP-manzilingiz, brauzeringizning dasturiy ta’minot
                <br/>versiyasi haqidagi ma’lumotlar, bizning saytimizga tashrif buyurgan kompyuterning operatsion
                <br/>tizimi, tashrif buyurganingizdan so’ng bizning saytimizga tashrif buyurgan saytlarning manzillari,
                <br/>siz ko’rsatgan qidiruv parametrlari sizni bizning saytimizga olib keldi. Vaziyatga qarab, bunday
                <br/>ma’lumotlar bizning saytimizga qaysi auditoriya tashrif buyurganligi to’g’risida xulosalar
                <br/>chiqarishga imkon beradi. Shu nuqtai nazardan, hech qanday shaxsiy ma’lumotlar ishlatilmaydi.
                <br/>Faqat anonim ma’lumotlardan foydalanish mumkin. Agar ma’lumot «Henkel» kompaniyasi
                <br/>va/yoki uning filiallari tomonidan tashqi provayderga uzatilsa, amaldagi Qonunchilik talablariga
                <br/>muvofiq ma’lumotlarni uzatishni kafolatlaydigan barcha mumkin bo’lgan texnik va tashkiliy
                <br/>choralar qabul qilinadi. Agar siz bizga ixtiyoriy ravishda shaxsiy ma’lumotlarni taqdim qilsangiz,
                <br/>biz bunday ma’lumotlarni amaldagi Qonunchilik talablaridan tashqari ishlatmaslik, qayta ishlash
                <br/>yoki uzatish majburiyatini olamiz. Shaxsiy ma’lumotlaringizni roziligisiz ishlatish va tarqatish
                <br/>faqat sud qarori asosida yoki Qozog’iston Respublikasi qonunlarida nazarda tutilgan boshqa
                <br/>tartibda amalga oshirilishi mumkin. Maxfiylik qoidalariga kiritilgan har qanday o’zgarishlar
                <br/>ushbu sahifada joylashtiriladi. Bu sizga har qanday vaqtda biz qanday ma’lumotlarni saqlashimiz
                <br/>va bunday ma’lumotlarni qanday to’plashimiz va saqlashimiz haqida ma’lumot olish imkonini
                <br/>beradi.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>3. Ma’lumotlar xavfsizligi</h2>
                <br/>«Henkel» kompaniyasi va uning filiallari shaxsiy ma’lumotlaringizni ehtiyotkorlik bilan saqlashga
                <br/>va shaxsiy ma’lumotlaringizni yo’qotish, noto’g’ri ishlatish yoki shaxsiy ma’lumotlarga
                <br/>o’zgartirishlar kiritishdan himoya qilish uchun barcha choralarni ko’rishga majbur. Kompaniya
                <br/>«Henkel» va uning filiallari hamkorlar, ular kompaniya «Henkel» va uning filiallari nomidan sizga
                <br/>xizmat ko’rsatish uchun zarur bo’lgan ma’lumotlarni foydalanish imkoniyatiga ega bo’lgan,
                <br/>kompaniya «Henkel» va uning filiallari ushbu ma’lumotlarning maxfiyligini hurmat qilish
                <br/>shartnomalarida belgilangan majburiyatlarni bajarish va taqdim etilgan ma’lumotlardan
                <br/>foydalanish huquqiga ega emas. boshqa maqsadlar.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>4. Voyaga etmagan foydalanuvchilarning shaxsiy ma’lumotlari</h2>
                <br/>«Henkel» kompaniyasi va uning filiallari 14 yilgacha bo’lgan foydalanuvchilarga nisbatan
                <br/>ma’lumot to’plamaydi. Agar bolaning ota-onasi yoki boshqa qonuniy vakillari bolalarning Henkel
                <br/>va uning filiallariga biron bir ma’lumot berganligini aniqlasalar va bola tomonidan taqdim
                <br/>etilgan ma’lumotlar yo’q qilinishi kerak deb hisoblasalar, bunday ota-onalar yoki boshqa
                <br/>qonuniy vakillar biz bilan quyidagi manzilda bog’lanishlari kerak. Bunday holda, biz darhol
                <br/>shaxsiy ma’lumotlarni o’chirib tashlaymiz.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>5. Internetda kuzatish</h2>
                <br/>Ushbu sayt marketing va optimallashtirish maqsadida ma’lumotlarni to’playdi va
                <br/>saqlaydi.
                <br/>Siz har qanday vaqtda ma’lumotlarni to’plash va saqlashga roziligingizni bekor
                <br/>qilishingiz mumkin, ammo bu sharh «orqaga qaytish»ga ega bo’lmaydi. Buning
                <br/>uchun siz yuklab olishingiz mumkin bo’lgan tegishli Google ilovasini
                <br/>o’rnatishingiz kerak bu yerga https://tools.google.com/dlpage/gaoptout?hl=ru.
                <br/>Brauzeringizda o’rnatilgandan so’ng, ushbu dastur JavaScript kodini beradi (ga.JS)
                <br/>jamoa Google Analytics bo’linmasiga tashrif buyurish haqida hech qanday
                <br/>ma’lumot bermaydi.
                <br/>Shu bilan bir qatorda, shuningdek, mobil qurilmalarga nisbatan ushbu havoladan
                <br/>ushbu saytdagi ma’lumotlarni keyinchalik ro’yxatdan o’tkazishni bekor qilish uchun
                <br/>foydalanishingiz mumkin (bu variant faqat joriy brauzer uchun va faqat ma’lum bir
                <br/>domen uchun amal qiladi). Ushbu usul yordamida gadjetingizda o’chirish opsiyasi
                <br/>saqlanadi. Agar siz brauzeringizni «cookies» fayllaridan tozalasangiz, havolani
                <br/>yana ishlatishingiz kerak bo’ladi.
                <br/>Google siyosati bilan tanishishingizni tavsiya qilamiz
                <br/>(https://policies.google.com/?hl=ru) internetda kuzatuv haqida ko’proq ma’lumot
                <br/>olish uchun ma’lumotlarni himoya qilish sohasida.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>6. DoubleClick texnologiyasi – Google Inc tomonidan taqdim etilgan xizmatdir.</h2>
                <br/>(1600 Amphitheatre Parkway, Mountain View, CA 94043), bu sizga tegishli
                <br/>reklamalarni ko’rsatishga imkon beradi. Buning uchun shaxsiy ma’lumotlarga ega
                <br/>bo’lmagan Cookie-fayllar ishlatiladi. Sizning IP manzilingiz bunday fayllarni
                <br/>yaratish uchun ishlatiladi. DoubleClick xizmati tomonidan ishlatiladigan Cookies
                <br/>brauzeringizga avtomatik ravishda yaratilgan identifikatsiya raqamlarini tayinlaydi
                <br/>va paydo bo’lgan reklamalarni kuzatib boradi. Bu Google va Affiliate saytlariga
                <br/>Henkel veb-saytlari va boshqa saytlarga tashrif buyurib, sizga reklama ko’rsatishga
                <br/>imkon beradi. Google o’z serveriga uzatadi va DoubleClick xizmati yordamida
                <br/>to’plangan ma’lumotlarni saqlaydi. Ushbu ma’lumotlar faqat Qozog’iston
                <br/>Respublikasi qonunlarida yoki ma’lumotlarni qayta ishlashga rozilik bergan
                <br/>hollarda uchinchi shaxslarga o’tkaziladi. Henkel veb-saytiga borib, yuqorida
                <br/>tavsiflangan xizmatdan foydalanishga va Google tomonidan ma’lumotlarni qayta
                <br/>ishlashga rozilik bildirasiz. Brauzer sozlamalarida Cookie fayllarini uzatishni
                <br/>blokirovka qilishingiz mumkin. Agar siz odatda Cookie fayllarini bloklamasangiz,
                <br/>lekin bu holda DoubleClick texnologiyasidan foydalanishni xohlamasangiz,
                <br/>havolani bosing (www.google.com/settings/ads/onweb), Google Inc.DoubleClick
                <br/>xizmatini o’chiradigan plaginni yuklab oling va o’rnating.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>7. Sizning istaklaringiz va savollaringiz</h2>
                <br/>Saqlangan ma’lumotlar «Henkel» kompaniyasi va/yoki uning filiallari tomonidan
                <br/>qonun hujjatlarida yoki shartnomada belgilangan saqlash muddati tugaganidan
                <br/>keyin yoki «Henkel» kompaniyasi va/yoki uning filiallari ma’lum ma’lumotlarni
                <br/>saqlashni bekor qilsa o’chiriladi. Siz istalgan vaqtda Henkel ma’lumotlar bazasidan
                <br/>va/yoki uning filiallaridan siz haqingizda ma’lumotni olib tashlashni talab
                <br/>qilishingiz mumkin. Bundan tashqari, istalgan vaqtda shaxsiy ma’lumotlaringizni
                <br/>ishlatish yoki qayta ishlashga roziligingizni bekor qilish huquqiga egasiz. Bunday
                <br/>hollarda, shuningdek, shaxsiy ma’lumotlaringiz bilan bog’liq boshqa istaklaringiz
                <br/>bo’lsa, sizdan Qozog’iston Respublikasidagi «Henkel» filialining korporativ
                <br/>aloqalar bo’limiga – «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд
                <br/>Коукасас») MChJ manziliga pochta orqali xat yuborishingizni so’raymiz.:
                <br/>050012, Qozog’iston Respublikasi, Olmaota shahri, Masanchi ko’chasi, 78. Agar
                <br/>biz siz haqingizda ma’lumot to’playotganimizni va agar shunday bo’lsa, qanday
                <br/>ma’lumotlarni to’plashni bilmoqchi bo’lsangiz, biz bilan bog’lanishingizni
                <br/>so’raymiz. Biz sizning istaklaringizni darhol bajarishga harakat qilamiz.
            </div>
            <Footer/>
        </div>
    )
}
