import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const UZRights = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Huquqiy qoidalar</title></Helmet>
            <div style={{maxWidth:800, marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Huquqiy qoidalar</h2>
                <br/>Doimiy texnik yaxshilanishlar va tegishli huquqiy tuzilmaning o’zgarishi tufayli vaqti-vaqti bilan
                <br/>quyidagi foydalanish shartlarini o’zgartirish va/yoki to’ldirish kerak bo’ladi. Shuning uchun biz
                <br/>foydalanuvchilarga har bir saytga tashrif buyurishdan oldin foydalanish shartlarini ko’rib
                <br/>chiqishni va mumkin bo’lgan o’zgarishlar va/yoki qo’shimchalarni hisobga olishni so’raymiz.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Kirish</h2>
                <br/>Ushbu sayt («sayt») «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд
                <br/>Коукасас») MChJ («Хенкель компаниясы») Olmaota shahri, Olmali tumani, Masanchi ko‘chasi,
                <br/>78-uy, 050012.
                <br/><br/>Saytdan foydalanib, uning mehmoni saytdan foydalanish uchun quyidagi shartlar bilan hech
                <br/>qanday cheklovlarsiz yoki olib qo’yilmasdan rozi bo’ladi.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Saytdan foydalanishning umumiy qoidalari</h2>
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>1. Mualliflik huquqi</h3>
                <br/>Ushbu saytning mazmuni mualliflik huquqi bilan himoyalangan. Xususan, ushbu sayt mazmunini
                <br/>uchinchi shaxslar tomonidan uchinchi tomon resurslarida, shu jumladan elektron ommaviy
                <br/>axborot vositalarida joylashtirish yoki qayta ishlashni takrorlash, moslashtirish, tarjima qilish,
                <br/>joylashtirish va qayta ishlash mualliflik huquqi bilan himoyalangan. Bunday ma’lumotlardan
                <br/>to’liq yoki qisman foydalanishga faqat Henkel kompaniyasining oldindan yozma roziligi bilan yo’l
                <br/>qo’yiladi. Axborot yoki ma’lumotlarning har qanday takrorlanishi, xususan, matnlar yoki matn
                <br/>qismlari yoki grafik materiallardan foydalanish (Henkel kompaniyasiga tegishli fotosuratlar
                <br/>bundan mustasno, matbuotda joylashtirilgan) faqat Henkel kompaniyasining oldindan yozma
                <br/>roziligi bilan ruxsat etiladi. Tarqatish va ko’paytirish huquqlari Henkel kompaniyasiga tegishli.
                <br/><br/>Henkel tomonidan saytda joylashtirilgan fotosuratlar faqat tahririyat uchun ishlatilishi mumkin.
                <br/>Tahririyat maqsadlari uchun o’zgartirilgan va/yoki elektron versiyada suratga olingan
                <br/>fotosuratlar mualliflik huquqiga ega bo’lishi kerak » © [yil] «Henkel Central Asia & Caucasus»
                <br/>(«Хенкель Сентрал Эйша энд Коукасас») MChJ. Barcha huquqlar himoyalangan. Qayta chop
                <br/>etish bepul, ammo Henkel kompaniyasiga qayta nashr etilgan materialning nusxasini yuborish
                <br/>kerak.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>2. Savdo belgilari</h3>
                <br/>Henkel logotipi va barcha mahsulot nomlari va / yoki mahsulot dizayni Henkel intellektual
                <br/>mulkining ro’yxatdan o’tgan savdo belgilaridir yoki uning filiallari va rasmiy yoki filiallarida,
                <br/>litsenziatlarda ko’zda tutilgan ob’ektlardir. Bundan tashqari, Qozog’iston Respublikasi
                <br/>qonunchiligi, kompaniya Henkel roziligisiz bu materiallar har qanday boshqa norasmiy
                <br/>foydalanish yoki uchinchi shaxslarning bunday roziligini berish uchun vakolatli taqiqlanadi va
                <br/>savdo belgilari to’g’risidagi qonun hujjatlari buzilishi hisoblanadi, mualliflik huquqi, intellektual
                <br/>mulk yoki adolatsiz raqobat to’g’risidagi qonun hujjatlari boshqa huquqlari.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>3. Uchinchi tomon saytlarining mazmuni bilan aloqani rad etish </h3>
                <br/><br/>a. Ushbu sayt sahifalarida uchinchi shaxslar tomonidan qo’llab-quvvatlanadigan va mazmuni
                <br/>Henkel tomonidan noma’lum bo’lgan boshqa saytlarga havolalar (ya’ni «ko’priklar») mavjud.
                <br/>Henkel faqat bunday saytlarga kirishga yordam beradi va ularning mazmuni uchun hech qanday
                <br/>javobgarlikni o’z zimmasiga olmaydi. Uchinchi tomon veb-saytlariga aloqalarimiz faqat siz uchun
                <br/>bunday saytlarni osonlashtirishga qaratilgan. Bizning saytimiz yuborgan sahifalardagi bayonotlar
                <br/>bizning bayonotlarimiz emas. Bizning saytimizda berilgan uchinchi tomon saytlarining har
                <br/>qanday mazmuni bilan aloqa qilishdan bosh tortamiz. Xususan, amaldagi Qonunchilik
                <br/>normalarini yoki bunday saytlar sahifalarida yuzaga keladigan uchinchi shaxslarning huquqlarini
                <br/>buzganlik uchun javobgarlikni o’z zimmamizga olmaymiz.
                <br/><br/>b. Henkel kompaniyasining veb-saytidan olishingiz mumkin bo’lgan saytlarga nisbatan ushbu
                <br/>saytlarning egalari ushbu saytlarning mazmuni, shuningdek, ushbu saytlarda taqdim etilgan
                <br/>tovarlarni sotish va bunday saytlardan foydalangan holda Internet orqali olingan har qanday
                <br/>buyurtmalarni qayta ishlash va bajarish uchun to’liq javobgardir.
                <br/><br/>c. Henkel kompaniyasi mualliflik huquqi, tovar belgilari yoki boshqa intellektual mulk
                <br/>huquqlarining buzilishi, saytda mavjud bo’lgan shaxsiy nomulkiy huquqlarning buzilishi uchun
                <br/>hech qanday javobgarlikni o’z zimmasiga olmaydi.
                <br/><br/>d. Ushbu rad etish saytda ko’rsatilgan barcha havolalar va foydalanuvchi bunday havolalar
                <br/>orqali yuborilgan veb-saytlarning mazmuni uchun amal qiladi.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>4. Umumiy rad etish</h3>
                <br/>Ushbu saytdan foydalanish natijasida yuzaga kelgan Henkel kompaniyasining har qanday
                <br/>javobgarligi – qonuniy asoslardan qat’i nazar, qiynoq – haqiqiy zarar bilan cheklangan.
                <br/>Ushbu qoidalar Henkel kompaniyasining mahsulot sifati past bo’lganligi uchun javobgarlik
                <br/>to’g’risidagi qonun hujjatlariga yoki Henkel tomonidan berilgan kafolatlardan kelib chiqadigan
                <br/>javobgarlikka ta’sir qilmaydi. Yuqoridagi javobgarlik cheklovlari inson hayoti va sog’lig’iga zarar
                <br/>etkazgan taqdirda ham qo’llanilmaydi.
                <br/><br/>Henkel veb-saytni viruslardan himoya qilish uchun katta kuch sarflamoqda, ammo biz ularning
                <br/>to’liq etishmasligini kafolatlay olmaymiz. Shu munosabat bilan, foydalanuvchilarga Henkel veb-
                <br/>saytidan hujjatlar va ma’lumotlarni yuklab olishdan oldin kompyuterlarini viruslardan himoya
                <br/>qilish (virus skanerlaridan foydalanish) bo’yicha mustaqil choralar ko’rishni tavsiya etamiz.
                <br/><br/>Henkel saytning alohida variantlarida xatolar yoki nosozliklar yo’qligiga kafolat bermaydi,
                <br/>shuningdek ushbu variantlarning mavjudligi uchun javobgar emas.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>5. Uzoq muddatli va niyatli bayonotlar</h3>
                <br/>Henkel kompaniyasining missiyasi va maqsadlari bilan bog’liq va uzoq muddatli xarakterga ega
                <br/>bo’lgan saytda joylashtirilgan bayonotlar bizning so’nggi ma’lumotimiz va qilingan
                <br/>bayonotlarning to’g’riligiga ishonchimiz asosida beriladi. Biroq, aslida Henkel kompaniyalari
                <br/>tomonidan erishilgan natijalar ushbu uzoq muddatli bayonotlardan sezilarli darajada farq qilishi
                <br/>mumkin, chunki natijalar raqobat va makroiqtisodiy tabiatning bir qator omillariga bog’liq
                <br/>bo’lib, ular ba’zi hollarda Henkel nazorati ostida emas. Henkel sayt sahifalarida joylashtirilgan
                <br/>uzoq muddatli bayonotlarini doimiy ravishda tuzatishga intilmaydi.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>6. Henkel Mahsulotlari</h3>
                <br/>
                <br/>Ushbu sayt sahifalarida joylashtirilgan mahsulotlar Henkel kompaniyasining bir qismi bo’lgan va
                <br/>dunyoning turli mamlakatlarida joylashgan kompaniyalar tomonidan ishlab chiqarilgan
                <br/>mahsulotlarning namunasidir. Henkel sizning mamlakatingiz iste’molchilari uchun maxsus
                <br/>mahsulotlar mavjudligiga kafolat bermaydi.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>7. Boshqa qoidalar</h3>
                <br/>Ushbu umumiy foydalanish shartlari Qozog’iston Respublikasi qonunlariga muvofiq tartibga
                <br/>solinadi va talqin etiladi, BMTning tovarlarni xalqaro sotish shartnomalari to’g’risidagi
                <br/>Konventsiyasini hisobga olmagan holda, ushbu konventsiyani ushbu umumiy foydalanish
                <br/>shartlariga qo’llash aniq chiqarib tashlanadi. Ushbu sayt bilan bog’liq nizolarni hal qilish joyi,
                <br/>amaldagi Qonunchilik imkon qadar, Qozog’iston Respublikasi. Agar ushbu umumiy shartlarning
                <br/>biron bir qoidasi bekor qilinsa, bu boshqa qoidalarning haqiqiyligiga ta’sir qilmasligi kerak.
            </div>
            <Footer/>
        </div>
    )
}
