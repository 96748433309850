import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const GEPersonal = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>თანხმობა პრესონალური მონაცემების
                დამუშავებაზე</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Consent</h2>
                <br/>By registering on the website, the user confirms consent to the processing of their personal
                <br/>data by Henkel Central Asia & Caucasus LLP (Henkel Central Asia & Caucasus), hereinafter
                <br/>referred to as the "Customer", in accordance with the Policy of Henkel Central Asia & Caucasus
                <br/>LLP (Henkel Central Asia & Caucasus) regarding the processing of personal data.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>For the purposes of:</h3>

                <br/>✔ collecting information about consumers of the Customer's products, in particular about their
                <br/>quality;
                <br/>✔ disseminating information about the Customer's products;
                <br/>✔ informing participants about the Customer's products, as well as about events held and (or)
                <br/>organized by the Customer;
                <br/>✔ sending any other informational and advertising messages;
                <br/>✔ and messages for the purpose of conducting marketing analysis and preparing statistical
                <br/>information.

                <br/>The Consumer gives consent to Henkel Central Asia & Caucasus LLP, including persons acting on
                <br/>his behalf, to process, including collecting, recording, systematizing, accumulating, storing,
                <br/>clarifying (updating, changing), using, transferring (providing, accessing), the following personal
                <br/>data:

                <br/>● First name
                <br/>● Last name
                <br/>● Email (address)
                <br/>● Mobile phone

                <br/>The Client's consent is valid until the date of its revocation by the Client sending a written
                <br/>statement to Henkel Central Asia & Caucasus LLP in accordance with the Henkel Central Asia &
                <br/>Caucasus LLP Policy regarding the processing of personal data.
            </div>
            <Footer/>
        </div>
    )
}
