import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const GEPrivacy = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>კონფედენციალურობა</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Privacy Policy</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>1 General</h3>
                <br/>Henkel Central Asia & Caucasus, hereinafter referred to as Henkel, respect the privacy of each
                <br/>person who visits our website. Now we would like to inform you of the type of data collected by
                <br/>Henkel and how they are used. You will also learn how you can check the accuracy of this
                <br/>information and arrange for the deletion of these data by Henkel. Data are collected, processed
                <br/>and used in conformity with the data protection regulations of the country in which the
                <br/>responsible data processing department is located. We take any action required to ensure
                <br/>compliance.
                <br/>
                <br/>This Privacy Statement does not apply to websites accessible from hyperlinks on Henkel
                <br/>websites.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>2 Collection, use and processing of personal data</h3>
                <br/>We collect data relating to a specific person for processing and usage purposes only, if you
                <br/>voluntarily decide to enter the data or expressly give your consent thereto. By doing so, you
                <br/>accept the following terms of use.
                <br/>
                <br/>When visiting our website, certain data are automatically stored on our servers for the purpose
                <br/>of system administration or for statistical or backup purposes. These data comprise the name of
                <br/>your Internet service provider, in some cases your IP address, the version of your browser
                <br/>software, the operating system of the computer used to access our website, the website you
                <br/>are using to visit us, the websites that you visit while you are with us and, if applicable, any
                <br/>search terms you have been using to find our website. Depending on the circumstances, such
                <br/>data may allow that conclusions be drawn about certain visitors to the website. However, no
                <br/>person-related data are used in this context. Such data are used solely after having been
                <br/>rendered anonymously. If Henkel transfers data to an external service-provider, technical and
                <br/>organizational measures will be taken which guarantee that their transmission occurs in
                <br/>conformity with the statutory data protection regulations.
                <br/>
                <br/>If you voluntarily supply us with personal-related data, we shall not use, process or transfer
                <br/>these data beyond the limits permitted by statute or defined by yourself in your declaration of
                <br/>consent. Furthermore, we will transfer your data only, if we are obliged to do so by official or
                <br/>court orders.
                <br/>
                <br/>Any changes to this Privacy Statement will be published on this page. This enables you to
                <br/>inform yourself at any time about which data we are storing and how we collect and use such
                <br/>data.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>3 Security</h3>
                <br/>Henkel will store your data safely and, therefore, take all precautionary measures to protect
                <br/>your data against loss, abuse or changes. Contractual partners of Henkel who have access to
                <br/>your data in order to provide services to you on behalf of Henkel are contractually obliged to
                <br/>keep such information in confidence and may not use these data for any other purpose. In
                <br/>some cases, it will be necessary that we transfer your inquiries to affiliated companies of
                <br/>Henkel. Also in these cases your data will be treated confidential.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>4 Person-related Data of Children</h3>
                <br/>Henkel does not wish to collect data on children below the age of 14 years. Where necessary,
                <br/>Henkel will expressly draw the children's attention in an appropriate place to the fact that they
                <br/>shall not send any person-related information to Henkel. Should parents or other legal
                <br/>guardians discover that the children under their supervision have made person-related data
                <br/>available to Henkel, we request that they contact us at the address below (see 6 below), if
                <br/>these data shall be deleted. We shall then arrange for the deletion of these data without delay.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>5 Cookies</h3>
                <br/>To learn more about cookies used on this site, please follow this link.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>6 Web Tracking</h3>
                <br/>(1) This website collects and stores data for marketing and optimization.
                <br/>This data can be used to create pseudonymized user profiles. We use cookies for that purpose.
                <br/>Cookies allow us to recognize your web browser.
                <br/>Information about the usage of the website which is generated by means of such cookies are
                <br/>transferred to and stored on a server operated by Google in the USA. The generated data is not
                <br/>used to identify you and the data is supplemented with person related data about you.
                <br/>Your IP-address will be truncated by Google within member states of the European Union and
                <br/>other states of the European Economic Area before being transferred in the US. Only in some
                <br/>exceptional cases, will the full IP-address be transferred to Googles servers in the US where it
                <br/>will then be truncated.
                <br/>You may withdraw your consent to the collection and storage of data any time with future
                <br/>effect.
                <br/>This requires you install an according add-on from Google which can be downloaded here.
                <br/>When the add-on is installed in your browser, it tells Google’s JavaScript Code (ga.js) not to
                <br/>transmit any information about the visit of the web site to Google Analytics.
                <br/>Alternatively to installing a browser add-on or for mobile devices, you may use this link, to
                <br/>disable future recording of data on this website (this opt-out only applies to the current
                <br/>browser and only for this domain). This method stores an opt-out cookie on your device. If you
                <br/>delete cookies in your browser you’ll have to use the link anew.
                <br/>Please refer to Google’s data privacy policy for further information about web tracking.
                <br/>(2) Adobe Analytics
                <br/>Provided you have consented, this website uses Adobe Analytics, a web analytics service
                <br/>provided by Adobe Systems Software Ireland Limited (4-6 Riverwalk, City West Business
                <br/>Campus, Saggart, Dublin 24, Ireland) (“Adobe”). For this purpose, a cookie is installed on your
                <br/>device. This cookie will collect data such as browser and device information, your IP address,
                <br/>visited websites, and date and time of server request for the purpose of evaluating your use of
                <br/>the website, compiling reports on website activity for website operators and providing other
                <br/>services relating to website activity and internet usage to the website provider.
                <br/>Adobe (as a controller) may use your data for any own purposes as for profiling and linking your
                <br/>data to other data collected about you (e.g. through your Adobe Account).
                <br/>The information generated by the cookie about your use of the website (including your IP
                <br/>address) will be transmitted to and processed by Adobe on servers in the United Kingdom. For
                <br/>this third country data transfer, an adequate level of data protection is ensured by standard
                <br/>contractual clauses, Art. 46 GDPR, pending an adequacy decision by the European Commission
                <br/>(Art. 45 GDPR).
                <br/>We have activated the IP anonymization, which means that the last octet (the last portion) of
                <br/>the IP address is immediately hidden when the IP address is collected by Adobe. This
                <br/>anonymization is performed prior to any processing of the IP address. Only an approximate
                <br/>location will be available for the purpose of statistical analysis. After the geo-lookup,
                <br/>everywhere in Analytics the IP addresses are obfuscated – replaced with :: X.X.X.X.
                <br/>By allowing cookies on the Henkel-website you agree to the use of the above-mentioned data
                <br/>and the previously described processing by Adobe.
                <br/>You find more information about privacy in Adobe services here:
                <br/>https://www.adobe.com/privacy/policy.html
                <br/>Objection to data collection:
                <br/>You can withdraw your consent at any time with effect for the future by disabling cookies on
                <br/>our website under "Cookies" at "Cookie settings". If you agree to the use of cookies in general,
                <br/>but however, you are uncomfortable with the use of Adobe Analytics cookies, you can follow
                <br/>this link (https://www.adobe.com/privacy/opt-out.html) and deactivate the Adobe Analytics
                <br/>collection and use of data (cookies and IP address).”
                <br/>(3) DoubleClick is a service we use that is provided by Google Inc., 1600 Amphitheatre Parkway,
                <br/>Mountain View, CA 94043, to display relevant ads to you. For this purpose, cookies are used
                <br/>that do not contain any personal information. Your IP address will be used when generating
                <br/>these cookies. The DoubleClick cookies utilize a pseudonymous identification number assigned
                <br/>to your browser to collect on-screen and other ads. This allows Google and its partner sites to
                <br/>display ads based on previous visits to Henkel webpages or other websites. Google transmits
                <br/>and saves the information gathered from the DoubleClick cookies to a server it manages that
                <br/>may be in an overseas country.
                <br/>The data collected by the DoubleClick cookies is only transferred to third parties in accordance
                <br/>with the law and Henkel’s data processing agreement with Google. By accessing any Henkel
                <br/>website you agree to the use of the above-mentioned DoubleClick cookie data for processing by
                <br/>Google.
                <br/>You can prevent cookies from being stored in your browser settings. If you are uncomfortable
                <br/>with the use of DoubleClick cookies, you can follow this link. to download and install a browser-
                <br/>plugin to deactivate the DoubleClick service by Google.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>7. Сіздің тілектеріңіз бен сұрақтарыңыз</h3>
                <br/>Stored data will be deleted by Henkel upon expiration of the statutory or contractual filing
                <br/>period or if Henkel no longer requires the same. At any time, you may, of course, ask for the
                <br/>deletion of your data. You are also entitled at any time to revoke your consent to the use or
                <br/>processing of your person-related data with future effect. In these cases, or if you have any
                <br/>other wishes in connection with your person-related data, please send an e-mail or a letter to
                <br/>our data protection officer, Mr. Frank Liebich, Henkelstrasse 67, D 40191 Düsseldorf, Germany
                <br/>(E-Mail: Datenschutz@henkel.com). Please also contact us this way, if you would like to know
                <br/>whether we have collected data about you and, if so, which data. We shall endeavor to comply
                <br/>with your wish immediately.
            </div>
            <Footer/>
        </div>
    )
}
