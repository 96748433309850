import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const GEInternal = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>შესაყვანი მონაცემები</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Imprint</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Company:</h3>
                Henkel Central Asia & Caucasus LLC
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Registered Office:</h3>
                78, Masanchi Street 050012, Almaty, Kazakhstan
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Postal Address:</h3>
                78, Masanchi Street 050012, Almaty, Kazakhstan
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Main Address:</h3>
                78, Masanchi Street 050012, Almaty, Kazakhstan
                <br/><br/>Henkel Central Asia & Caucasus, 78, Masanchi Street 050012, Almaty, Kazakhstan
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Consumer Service:</h3>
                Henkel Central Asia & Caucasus LLC
                <br/><br/>+7 7272 44 33 99
                <br/><br/> More information about Henkel:
                <br/><br/>www.henkel.com
            </div>
            <Footer/>
        </div>
    )
}
