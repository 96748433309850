import Logo from '../../assets/logo.webp';
import {useNavigate} from "react-router";

export const Header = () => {
    const history = useNavigate();
    const toMain = () => {
        if (window.location.pathname === '/'){
            history(`/`);
            window.location.replace(`/`)
        }else if(window.location.pathname.includes('ru')){
            history(`/ru`);
        }else if(window.location.pathname.includes('uz')){
            history(`/uz`);
        }else if(window.location.pathname.includes('kz')){
            history(`/kz`);
        }else if(window.location.pathname.includes('ge')){
            history(`/ge`);
        }
    }
    const newPath = (lang) => {
        if (window.location.pathname === '/'){
            window.location.replace(`/${lang}`)
        }else if(window.location.pathname.includes('ru')){
            const newURL = window.location.pathname.replace(/ru/i, `${lang}`);
            window.location.replace(`${newURL}`);
        }else if(window.location.pathname.includes('uz')){
            const newURL = window.location.pathname.replace(/uz/i, `${lang}`);
            window.location.replace(`${newURL}`);
        }else if(window.location.pathname.includes('kz')){
            const newURL = window.location.pathname.replace(/kz/i, `${lang}`);
            window.location.replace(`${newURL}`);
        }else if(window.location.pathname.includes('ge')){
            const newURL = window.location.pathname.replace(/ge/i, `${lang}`);
            window.location.replace(`${newURL}`);
        }
    }
    return(
        <header>
            <img onClick={toMain} className={"logo"} src={Logo}/>
            <div className={"langs"}>
                <a onClick={()=>{
                    newPath('ru');
                }} style={
                    window.location.pathname === '/' || window.location.pathname.includes('ru') ?
                        {color:"white", textDecoration:"none"} :
                        {color:"rgba(255, 255, 255, 0.6)", textDecoration:"none"}}>RU</a>
                <a onClick={()=>{
                    newPath('kz');
                }} style={
                    window.location.pathname.includes("kz") ?
                    {
                        borderRight:"1px solid #FFF",
                        borderLeft: "1px solid #FFF",
                        color:"white",
                        textDecoration:"none"
                    } :
                        {
                            borderRight:"1px solid #FFF",
                            borderLeft: "1px solid #FFF",
                            color:"rgba(255, 255, 255, 0.6)",
                            textDecoration:"none"
                        }
                }>KZ</a>
                <a onClick={()=>{
                    newPath('uz');
                }} style={
                    window.location.pathname.includes("uz") ?
                        {
                            borderRight:"1px solid #FFF",
                            borderLeft: "1px solid #FFF",
                            color:"white",
                            textDecoration:"none"
                        } :
                        {
                            borderRight:"1px solid #FFF",
                            borderLeft: "1px solid #FFF",
                            color:"rgba(255, 255, 255, 0.6)",
                            textDecoration:"none"
                        }
                }>UZ</a>
                <a style={
                    window.location.pathname.includes("ge") ?
                    {color:"white", textDecoration:"none"} :
                        {color:"rgba(255, 255, 255, 0.6)", textDecoration:"none"}
                } onClick={()=>{
                    newPath('ge');
                }}>GE</a>
            </div>
        </header>
    )
}
