import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const KZPrivacy = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Құпиялылық саясаты</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Құпиялылық саясаты</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>1. Жалпы ережелер</h3>
                <br/>«Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС (бұдан әрі –
                <br/>«Хенкель») және оның үлестес тұлғалары осы сайтқа кірген кез келген тұлғаның құпия
                <br/>ақпаратына құрметпен қарайды. Біз Сізге қандай деректерді жинайтынымыз және
                <br/>жиналған деректерді қалай пайдаланатынымыз туралы хабарлағымыз келеді.
                <br/><br/>Сондай-ақ, сіз жиналған ақпараттың дәлдігін қалай тексеруге және бізге осындай
                <br/>ақпаратты жою туралы нұсқау беруге болатындығын білетін боласыз. Деректер жеке
                <br/>деректерді қорғауға жауапты «Хенкель» компаниясының тиісті үлестес тұлғасы
                <br/>орналасқан мемлекеттің қолданыстағы заңнамасының талаптарына сәйкес қатаң түрде
                <br/>жиналады, өңделеді және пайдаланылады. Біз қолданыстағы заңнама талаптарының
                <br/>сақталуын қамтамасыз ету үшін қолдан келгеннің бәрін жасаймыз.
                <br/><br/>Осы сайтта жиналатын дербес деректермен барлық әрекеттер Қазақстан
                <br/>Республикасының 2013 жылғы 21 мамырдағы № 94-V «Дербес деректер және оларды
                <br/>қорғау туралы» Заңына сәйкес жүргізіледі.
                <br/><br/>Бұл өтініш «Хенкель» компаниясының сайтында гиперсілтемелер бар сайттарға
                <br/>қолданылмайды.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>2. Дербес деректерді жинау, пайдалану және өңдеу</h3>
                <br/>Біз ақпаратты өңдеу және пайдалану мақсатында ғана белгілі бір тұлғаларға қатысты
                <br/>ақпаратты жинауды жүзеге асырамыз, бұл ретте нақты міндеттерді орындау үшін қажетті
                <br/>және жеткілікті деректерді жинау жүзеге асырылады және Сіз ақпаратты ерікті түрде
                <br/>берген немесе оны пайдалануға өз келісіміңізді анық білдірген жағдайда ғана жүзеге
                <br/>асырылады. Сіз біздің сайтқа кірген кезде белгілі бір деректер жүйелік әкімшілендіру
                <br/>мақсаттары үшін немесе статистикалық немесе резервтік мақсаттар үшін «Хенкель»
                <br/>компаниясының және/немесе оның үлестес тұлғаларының серверлеріне автоматты түрде
                <br/>жазылады. Жазылатын ақпарат Сіздің интернет-провайдеріңіздің атауын, кейбір
                <br/>жағдайларда сіздің IP-мекенжайыңызды, сіздің браузеріңіздің бағдарламалық
                <br/>қамтылымының нұсқасы туралы деректерді, біздің сайтқа кірген компьютердің
                <br/>операциялық жүйесі туралы деректерді, сілтеме бойынша кіргеннен кейін біздің сайтқа
                <br/>кірген сайттардың мекенжайларын, сізді біздің сайтқа алып келген, сіз орнатқан іздеу
                <br/>параметрлерін қамтиды.
                <br/><br/>Жағдайларға байланысты мұндай ақпарат біздің сайтқа қандай аудитория кіретіні туралы
                <br/>қорытынды жасауға мүмкіндік береді. Алайда, бұл тұрғыда ешқандай жеке деректер
                <br/>пайдаланылмайды. Тек анонимді ақпарат пайдаланылуы мүмкін. Егер ақпаратты
                <br/>«Хенкель» компаниясы және/немесе оның үлестес тұлғалары сыртқы провайдерге
                <br/>беретін болса, деректерді қолданыстағы заңнаманың талаптарына сәйкес беруге кепілдік
                <br/>беретін барлық ықтимал техникалық және ұйымдастырушылық шаралар қабылданады.
                <br/><br/>Егер Сіз бізге дербес ақпаратты өз еркіңізбен беретін болсаңыз, біз мұндай ақпаратты
                <br/>қолданыстағы заңнама талаптарының шеңберінен шығатын тәсілмен пайдаланбауға,
                <br/>өңдемеуге және бермеуге міндеттенеміз. Сіздің жеке деректеріңізді Сіздің келісіміңізсіз
                <br/>пайдалану және тарату тек сот шешімі негізінде немесе Қазақстан Республикасының
                <br/>заңнамасында көзделген өзге тәртіппен ғана жүзеге асырылуы мүмкін. Құпиялылықты
                <br/>сақтау жөніндегі ережелерге енгізілетін кез келген өзгерістер осы бетте жарияланады. Бұл
                <br/>Сізге кез-келген уақытта бізде қандай деректер сақталатыны және осындай деректерді
                <br/>қалай жинайтынымыз және сақтайтынымыз туралы ақпарат алуға мүмкіндік береді.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>3. Деректер қауіпсіздігі</h3>
                <br/>«Хенкель» компаниясы және оның үлестес тұлғалары Сіздің дербес деректеріңізді ұқыпты
                <br/>сақтауға және Сіздің дербес деректеріңізді жоғалудан, дұрыс пайдаланбаудан немесе
                <br/>дербес деректерге өзгерістер енгізуден қорғау үшін барлық сақтық шараларын
                <br/>қабылдауға міндеттенеді. «Хенкель» компаниясының және оның үлестес тұлғаларының
                <br/>атынан Сізге қызмет көрсету үшін оларға қажет Сіздің деректеріңізге қол жеткізе алатын
                <br/>«Хенкель» компаниясының және оның үлестес тұлғаларының серіктестері «Хенкель»
                <br/>компаниясының және оның үлестес тұлғаларының алдында келісім-шарттарда бекітілген
                <br/>осы ақпараттың құпиялылығын сақтау міндеттемелерін алады және қандай да өзге
                <br/>мақсаттар үшін ұсынылатын деректерді пайдалануға құқығы жоқ.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>4. Кәмелетке толмаған пайдаланушылардың дербес деректері</h3>
                <br/>«Хенкель» компаниясы және оның үлестес тұлғалары 14 жасқа толмаған
                <br/>пайдаланушыларға қатысты ақпарат жинамайды. Егер баланың ата-аналары немесе өзге
                <br/>де заңды өкілдері балалардың «Хенкель» компаниясы мен оның үлестес тұлғалары үшін
                <br/>қандай да бір ақпаратты қолжетімді еткенін анықтаса және бала ұсынған деректер
                <br/>жойылуы тиіс деп есептесе, мұндай ата-аналар немесе өзге де заңды өкілдер төменде
                <br/>көрсетілген мекенжай бойынша бізбен хабарласуы қажет. Бұл жағдайда біз жеке
                <br/>ақпаратты дереу жоямыз.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>5. Интернет желісінде қадағалау</h3>
                <br/>Бұл сайт маркетинг және оңтайландыру мақсаттары үшін деректерді жинайды және
                <br/>сақтайды.
                <br/><br/>Сіз кез-келген уақытта деректерді жинауға және сақтауға келісіміңізді қайтарып алуға
                <br/>құқылысыз, бірақ бұл кері байланыс «кері күшке» ие болмайды. Бұл үшін сізге
                <br/>https://tools.google.com/dlpage/gaoptout?hl=ru осы жерден жүктеуге болатын тиісті Google
                <br/>қосымшасын орнату керек.
                <br/><br/>Сіздің браузеріңізде орнатылған бұл қосымша JavaScript (ga.js) кодына Google Analytics
                <br/>бөліміне сайтқа кіру туралы ешқандай ақпарат бермеу командасын береді.
                <br/><br/>Балама ретінде, сондай-ақ мобильді құрылғыларға қатысты Сіз осы сілтемені осы сайтта
                <br/>деректерді одан әрі тіркеуден бас тарту үшін пайдалана аласыз (бұл опция тек ағымдағы
                <br/>браузер үшін ғана және тек осы домен үшін ғана қолданылады). Бұл әдіс кезінде өшіру
                <br/>опциясы Сіздің гаджетіңізде сақталады. Егер Сіз браузеріңізді ‘cookies’ файлдарынан
                <br/>тазаласаңыз, онда сілтемені тағы бір рет пайдалануыңыз қажет.
                <br/><br/>Интернет желісінде қадағалау туралы толығырақ ақпарат алу үшін деректерді қорғау
                <br/>саласында Сізге Google (https://policies.google.com/?hl=ru) саясатымен танысуға кеңес
                <br/>береміз.
                <br/><br/><span style={{fontWeight:"bold"}}>6. DoubleClick технологиясы</span> –бұл Сізге тиісті жарнамаларды көрсетуге мүмкіндік беретін
                <br/>Google Inc. компаниясы ұсынатын қызмет (1600 Amphitheatre Parkway, Mountain View, CA
                <br/>94043). Осы мақсатта ешқандай дербес деректері жоқ cookies файлдары пайдаланылады.
                <br/>Сіздің IP мекенжайыңыз осындай файлдарды жасау үшін қолданылады. DoubleClick
                <br/>сервисі пайдаланатын сookies сіздің браузеріңізге автоматты түрде генерацияланған
                <br/>сәйкестендіру нөмірлерін береді және пайда болған жарнаманы қадағалайды. Бұл
                <br/>Google-ге және серіктестік сайттарға Henkel компаниясының сайттарына және басқа
                <br/>сайттарға кіруіңіздің негізінде жарнамаларды көрсетуге мүмкіндік береді. Google
                <br/>DoubleClick сервисінің көмегімен жиналған ақпаратты өзіне серверге жібереді және сол
                <br/>жерде сақтайды. Бұл деректер үшінші тұлғаларға Қазақстан Республикасының
                <br/>заңнамасында айқындалған немесе деректерді өңдеуге келісім берген жағдайларда ғана
                <br/>беріледі. Henkel сайтына өту арқылы сіз жоғарыда сипатталған қызметті пайдалануға
                <br/>және Google компаниясының деректерді өңдеуіне келісім бересіз. Cookies файлдарын
                <br/>жіберуді сіз браузеріңіздің баптауларынан бұғаттай аласыз. Егер сіз әдетте Cookies
                <br/>файлдарын бұғаттамасаңыз, бірақ бұл жағдайда DoubleClick технологиясын қолданғыңыз
                <br/>келмесе, сілтеме бойынша өтіңіз (www.google.com/settings/ads/onweb), GoogleInc
                <br/>компаниясының DoubleClick қызметін өшіретін плагинді жүктеңіз және орнатыңыз.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>7. Сіздің тілектеріңіз бен сұрақтарыңыз</h3>
                <br/>Сақталатын деректерді «Хенкель» компаниясы және/немесе оның үлестес тұлғалары
                <br/>заңнамада немесе шартта белгіленген сақтау кезеңі өткеннен кейін не «Хенкель»
                <br/>компаниясының өзі және/немесе оның үлестес тұлғалары қандай да бір деректердің
                <br/>сақталуын жойған жағдайда өшіреді. Сіз кез келген уақытта «Хенкель» компаниясының
                <br/>және/немесе оның үлестес тұлғаларының деректер базасынан Сіз туралы ақпаратты алып
                <br/>тастауды талап етуге құқылысыз. Сіз сондай-ақ кез келген уақытта Сіздің дербес
                <br/>деректеріңізді пайдалануға немесе қайта өңдеуге келісіміңізді кері қайтарып алуға
                <br/>құқылысыз. Мұндай жағдайларда, сондай-ақ егер Сіздің өзіңіздің жеке деректеріңізге
                <br/>байланысты қандай да бір басқа тілектеріңіз болса, Қазақстан Республикасындағы
                <br/>«Хенкель» үлестес тұлғасының Корпоративтік коммуникациялар бөлімінің – «Henkel
                <br/>Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС-нің мекенжайына
                <br/>хат жіберуіңізді сұраймыз: 050012, Қазақстан Республикасы, Алматы қ., Масанчи к-сі, 78.
                <br/>Егер біздің Сіз туралы деректерді жинайтынымызды және егер солай болса, қандай
                <br/>деректерді жинайтынымызды білгіңіз келсе, бізбен хабарласуыңызды сұраймыз. Біз
                <br/>Сіздің тілектеріңізді дереу орындауға тырысамыз.
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Компания:</h3>*/}
                {/*«Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Кәсіпорынның заңды мекенжайы:</h3>*/}
                {/*Алматы қ., Алмалы ауданы, Масанчи көш., 78, 050012*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Пошталық мекенжай:</h3>*/}
                {/*Алматы қ., Алмалы ауданы, Масанчи көш., 78, 050012*/}
                {/*<br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Қазақстандағы тұтынушыларға қызмет көрсету:</h3>*/}
                {/*«Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС*/}
                {/*<br/><br/>тел: +7 7272 44 33 99*/}
                {/*<br/><br/> Henkel туралы қосымша ақпарат алу үшін:*/}
                {/*<br/><br/>www.henkel.com*/}
            </div>
            <Footer/>
        </div>
    )
}
