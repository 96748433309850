import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Rights = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Правовые положения</title></Helmet>
            <div style={{maxWidth:800, marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Правовые положения</h2>
                <br/>По причине постоянных технических усовершенствований и изменений
                <br/>соответствующей правовой структуры, периодически возникает необходимость
                <br/>изменения и/или дополнения приведенных ниже условий использования. Поэтому мы
                <br/>просим пользователей просматривать условия использования перед каждым
                <br/>посещением сайта и принимать во внимание возможные изменения и/или
                <br/>дополнения.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Введение</h2>
                <br/>Данный сайт («Сайт») представлен ТОО «Henkel Central Asia & Caucasus» («Хенкель
                <br/>Сентрал Эйша энд Коукасас»), г.Алматы, Алмалинский р-н, ул.Масанчи, д.78, 050012
                <br/>(«Компания Хенкель»). Компания предприняла все возможное для того, чтобы
                <br/>размещенная на данном сайте информация о ТОО «Henkel Central Asia & Caucasus»
                <br/>(«Хенкель Сентрал Эйша энд Коукасас»), её аффилированных лицах и третьих лицах была
                <br/>полной и достоверной.
                <br/>Пользуясь Сайтом, его посетитель соглашается без каких-либо ограничений или
                <br/>изъятий со следующими условиями пользования Сайтом.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Общие правила пользования Сайтом</h2>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>1. Авторское право</h2>
                <br/>Содержание данного Сайта защищено авторским правом. В частности, любое
                <br/>воспроизведение, адаптация, перевод, размещение и переработка содержания
                <br/>данного Сайта третьими лицами на сторонних ресурсах, включая размещение или
                <br/>переработку в электронных средствах массовой информации, защищены авторским
                <br/>правом. Любое использование целиком или частично такой информации допускается
                <br/>только с предварительного письменного согласия Компании Хенкель. Любое
                <br/>воспроизведение информации или данных, в частности, использование текстов или
                <br/>фрагментов текстов или графических материалов (за исключением фотографий,
                <br/>принадлежащих Компании Хенкель, размещенных в прессе) допускается только с
                <br/>предварительного письменного согласия Компании Хенкель. Права на
                <br/>распространение и воспроизведение принадлежат Компании Хенкель.
                <br/>Фотографии, размещенные Компанией Хенкель на Сайте, могут быть использованы
                <br/>только для редакционных целей. Фотографии, которые воспроизводятся и/или в
                <br/>электронную версию которых в редакционных целях вносятся изменения, должны
                <br/>иметь обозначение авторского права «© [год] ТОО «Henkel Central Asia & Caucasus». Все
                <br/>права защищены». Перепечатка является бесплатной, однако, в адрес Компании Хенкель
                <br/>необходимо направить копию перепечатанного материала.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>2. Товарные знаки</h2>
                <br/>Логотип Компании Хенкель, и все наименования продукции и/или дизайн продукции
                <br/>являются зарегистрированными товарными знаками или объектами
                <br/>интеллектуальной собственности Компании Хенкель, ее дочерних и
                <br/>аффилированных лиц, лицензиаров. Кроме случаев, предусмотренных
                <br/>законодательством РК, любое иное официальное или неофициальное
                <br/>использование данных материалов без согласия Компании Хенкель или
                <br/>уполномоченных ей на дачу такого согласия третьих лиц запрещено и представляет
                <br/>собой нарушение законодательства о товарных знаках, авторского права, иных прав
                <br/>интеллектуальной собственности или законодательства о недобросовестной
                <br/>конкуренции.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>3. Отказ от связи с содержанием сайтов третьих лиц</h2>
                <br/>a. На страницах данного Сайта содержатся ссылки (т.е. «гиперссылки») на другие
                <br/>сайты, которые поддерживаются третьими лицами и содержание которых неизвестно
                <br/>Компании Хенкель. Компания Хенкель только способствует доступу к таким сайтам и
                <br/>не несет никакой ответственности за их содержание. Наши ссылки на сайты третьих
                <br/>лиц нацелены лишь на то, чтобы сделать для вас проще навигацию по таким сайтам.
                <br/>Заявления, содержащиеся на тех страницах, к которым отсылает наш Сайт, не
                <br/>являются нашими заявлениями. Мы отказываемся от связи с любым содержанием
                <br/>любых сайтов третьих лиц, ссылки на которые даны на нашем сайте. В частности, мы
                <br/>не принимаем на себя ответственности за любые нарушения норм действующего
                <br/>законодательства или прав третьих лиц, которые имеют место на страницах таких
                <br/>сайтов.
                <br/>b. Применительно к сайтам, на которые можно зайти с Сайта Компании Хенкель,
                <br/>собственники данных сайтов несут полную ответственность за содержание данных
                <br/>сайтов, равно как и за продажу товаров, предлагаемых на данных сайтах, и за
                <br/>обработку и выполнение любых заказов, полученных через интернет с помощью
                <br/>таких сайтов.
                <br/>c. Компания Хенкель не несет никакой ответственности за любое нарушение
                <br/>авторских прав, прав на товарные знаки или иную интеллектуальную собственность,
                <br/>нарушение личных неимущественных прав, которые имеют место на сайте, ссылка
                <br/>на который содержится на сайте Компании Хенкель.
                <br/>d. В случае вынесения судебного решения или иного правового акта в отношении
                <br/>соглашения, договор заключается только между пользователем и владельцем
                <br/>соответствующего сайта или иным лицом, предлагающим заключить такой договор, в
                <br/>данном же случае, между Компанией Хенкель и пользователем. Просим вас обратить
                <br/>внимание на общие условия, предлагаемые компанией, на сайт которой дана ссылка.
                <br/>e. Данный отказ является действительным для всех ссылок, отображаемых на Сайте
                <br/>и для содержания веб-сайтов, на которые пользователь был направлен посредством
                <br/>таких ссылок.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>4. Общий отказ от ответственности</h2>
                <br/>Любая ответственность Компании Хенкель, причиненные в результате пользования
                <br/>данным Сайтом — независимо от юридического основания, включая деликт —
                <br/>ограничена реальным ущербом.
                <br/>Данные положения не затрагивают ответственность Компании Хенкель в
                <br/>соответствии с нормами законодательства об ответственности за
                <br/>недоброкачественность продукции или ответственность, вытекающую из гарантий,
                <br/>предоставленных Компанией Хенкель. Вышеуказанные ограничения ответственности
                <br/>также не применяются в случае причинения вреда жизни и здоровью человека.
                <br/>Компания Хенкель предпринимает значительные усилия для того, чтобы обезопасить
                <br/>сайт от вирусов, однако, мы не можем гарантировать их полное отсутствие. В связи с
                <br/>этим мы рекомендуем пользователям самостоятельно предпринимать меры по
                <br/>защите своих компьютеров от вирусов (использовать вирус-сканнеры) перед
                <br/>загрузкой документов и данных с сайта Компании Хенкель.
                <br/>Компания Хенкель не гарантирует отсутствие ошибок или сбоев в работе отдельных
                <br/>опций сайта, а равно не отвечает за доступность данных опций.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>5. Заявления долгосрочного характера и заявления о намерениях</h2>
                <br/>Размещенные на сайте заявления, относящиеся к миссии и целям Компании Хенкель
                <br/>и имеющие долгосрочный характер, даны на основе последней имеющейся у нас
                <br/>информации и нашей убежденности в правильности сделанных заявлений. Однако,
                <br/>результаты, достигнутые Компаний Хенкель в действительности, могут
                <br/>существенным образом отличаться от данных долгосрочных заявлений, поскольку
                <br/>результаты зависят от целого ряда факторов конкурентной и макроэкономической
                <br/>природы, которые в ряде случаев находятся вне контроля Компании Хенкель.
                <br/>Компания Хенкель не намеревается постоянно корректировать свои долгосрочные
                <br/>заявления, размещенные на страницах Сайта.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>6. Продукция Компании Хенкель</h2>
                <br/>Продукция, информация о которой размещена на страницах данного Сайта, является
                <br/>образцом продукции, производимой компаниями, входящими в Компанию Хенкель и
                <br/>базирующимися в разных странах мира. Компания Хенкель не гарантирует, что
                <br/>конкретная продукция доступна для потребителей именно вашей страны.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>7. Прочие положения</h2>
                <br/>Приведенные общие условия использования регламентируются и истолковываются в
                <br/>соответствии с законодательством Республики Казахстан, не принимая во внимание
                <br/>Конвенцию ООН о договорах международной купли-продажи товаров, тем самым
                <br/>применение данной конвенции к приведенным общим условиям использования
                <br/>однозначно исключается. Местом судебного решения споров, касающихся данного
                <br/>Сайта, насколько позволяет применяемое законодательство, является Республика
                <br/>Казахстан. Если какое-либо положение данных общих условий становится
                <br/>недействительным.
            </div>
            <Footer/>
        </div>
    )
}
