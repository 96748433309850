import {Header} from "./components/ru/Header";
import {useRoutes} from "./routes";
import {useEffect, useState} from "react";
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";

function App() {
    const currentDate = new Date();
    const location = useLocation();
    const routes = useRoutes();
    const pathname = window.location.pathname;
    const [lang, setLang] = useState({});
    useEffect(() => {
        if (pathname === "/"){
            setLang({buttonText: "Настройки Cookie", lang: "ru"})
        }else if (pathname.includes("ru")){
            setLang({buttonText: "Настройки Cookie", lang: "ru"})
        }else if (pathname.includes("kz")){
            setLang({buttonText: "Cookie параметрлері", lang: "kk"})
        }else if (pathname.includes("uz")){
            setLang({buttonText: "Cookie sozlamalari", lang: "uz"})
        }else if (pathname.includes("ge")){
            setLang({buttonText: "ქუქის პარამეტრები", lang: "ka"})
        }
    }, [location]);
    return (
        <>
            <Helmet>
                <html lang={lang.lang}/>
                <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="38de2272-fd1c-4a9b-adfe-6b2fcbc12d51" ></script>
                <script type="text/javascript">
                    {`${function OptanonWrapper() { }}`}
                </script>
            </Helmet>
            <div id="ot-sdk-cookie-policy" style={{ display: 'none' }}>
            </div>
            <div className={"container"}>
                <Header/>
                {routes}
            </div>
            <div className={"mobile-marginer"} style={{display:"flex", alignItems: "center", width:"100%", justifyContent:"center", flexDirection:"column", background: "#7c2b87"}}>
                <button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{background:"none", fontSize:12, textAlign:"center", color:"white", border:"none", fontWeight:"normal"}}>{lang.buttonText}</button>
                <p style={{fontSize:12, textAlign:"center", color:"white"}}>© {currentDate.getFullYear()} Henkel Central Asia & Caucasus</p>
            </div>
        </>
    );
}

export default App;
