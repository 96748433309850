import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Privacy = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Положение о конфиденциальности</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Положение о конфиденциальности</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>1. Общие положения</h3>
                <br/>ТОО «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») (далее
                <br/>«Хенкель») и её аффилированные лица с уважением относятся к конфиденциальной
                <br/>информации любого лица, ставшего посетителем данного сайта. Мы хотели бы
                <br/>проинформировать Вас о том, какие именно данные мы собираем и каким образом
                <br/>используем собранные данные.
                <br/>Вы также узнаете о том, как Вы можете проверить точность собранной информации и
                <br/>дать нам указание об удалении подобной информации. Данные собираются,
                <br/>обрабатываются и используются строго в соответствии с требованиями
                <br/>действующего законодательства того государства, в котором расположено
                <br/>соответствующее аффилированное лицо компании «Хенкель», отвечающее за защиту
                <br/>персональных данных. Мы делаем все возможное для обеспечения соответствия
                <br/>требованиям действующего законодательства.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>2. Сбор, использование и переработка персональных данных</h3>
                <br/>Мы осуществляем сбор информации, относящейся к определенным лицам, лишь в
                <br/>целях обработки и использования информации и только в том случае, если Вы
                <br/>добровольно предоставили информацию или явно выразили свое согласие на ее
                <br/>использование. Когда Вы посещаете наш сайт, определенные данные автоматически
                <br/>записываются на серверы компании «Хенкель» и/или её аффилированных лиц для
                <br/>целей системного администрирования или для статистических или резервных целей.
                <br/>Записываемая информация содержит название Вашего интернет-провайдера, в
                <br/>некоторых случаях Ваш IP-адрес, данные о версии программного обеспечения
                <br/>Вашего браузера, об операционной системе компьютера, с которого Вы посетили
                <br/>наш сайт, адреса сайтов, после посещения которых Вы по ссылке зашли на наш сайт,
                <br/>заданные Вами параметры поиска, приведшие Вас на наш сайт.
                <br/>В зависимости от обстоятельств подобная информация позволяет сделать выводы о
                <br/>том, какая аудитория посещает наш сайт. В данном контексте, однако, не
                <br/>используются никакие персональные данные. Использоваться может лишь
                <br/>анонимная информация. Если информация передается компанией «Хенкель» и/или
                <br/>её аффилированными лицами внешнему провайдеру, принимаются все возможные
                <br/>технические и организационные меры, гарантирующие передачу данных в
                <br/>соответствии с требованиями действующего законодательства.
                <br/>Если Вы добровольно предоставляете нам персональную информацию, мы
                <br/>обязуемся не использовать, не обрабатывать и не передавать такую информацию
                <br/>способом, выходящим за рамки требований действующего законодательства.
                <br/>Использование и распространение Ваших персональных данных без Вашего
                <br/>согласия возможно только на основании судебного решения или в ином порядке,
                <br/>предусмотренном законодательством РК. Любые изменения, которые будут внесены
                <br/>в правила по соблюдению конфиденциальности, будут размещены на данной
                <br/>странице. Это позволяет Вам в любое время получить информацию о том, какие
                <br/>данные у нас хранятся и о том, каким образом мы собираем и храним такие данные.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>3. Безопасность данных</h3>
                <br/>Компания «Хенкель» и её аффилированные лица обязуется бережно хранить Ваши
                <br/>персональные данные и принимать все меры предосторожности для защиты Ваших
                <br/>персональных данных от утраты, неправильного использования или внесения в
                <br/>персональные данные изменений. Партнеры компании «Хенкель» и её
                <br/>аффилированных лиц, которые имеют доступ к Вашим данным, необходимым им для
                <br/>предоставления Вам услуг от имени компании «Хенкель» и её аффилированных лиц,
                <br/>несут перед компанией «Хенкель» и её аффилированными лицами закрепленные в
                <br/>контрактах обязательства соблюдать конфиденциальность данной информации и не
                <br/>имеют права использовать предоставляемые данные для каких-либо иных целей.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>4. Персональные данные несовершеннолетних пользователей</h3>
                <br/>Компания «Хенкель» и её аффилированные лица не ведет сбор информации в
                <br/>отношении пользователей, не достигших 14 лет. Если родители или иные законные
                <br/>представители ребенка обнаружат, что дети сделали какую-либо информацию
                <br/>доступной для компании «Хенкель» и её аффилированных лиц, и сочтут, что
                <br/>предоставленные ребенком данные должны быть уничтожены, таким родителям или
                <br/>иным законным представителям необходимо связаться с нами по нижеуказанному
                <br/>адресу. В этом случае мы немедленно удалим личную информацию.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>5. Отслеживание в сети Интернет</h3>
                <br/>Данный сайт собирает и сохраняет данные для целей маркетинга и оптимизации.
                <br/>Вы вправе в любое время отозвать Ваше согласие на сбор и хранение данных, но
                <br/>этот отзыв не будет иметь «обратной силы». Для этого Вам необходимо установить
                <br/>соответствующее приложение Google, которое можно загрузить здесь
                <br/>https://tools.google.com/dlpage/gaoptout?hl=ru.
                <br/>Будучи установленным в Вашем браузере, это приложение выдаёт коду JavaScript
                <br/>(ga.js) команду не передавать никакой информации о посещении сайта в
                <br/>подразделение Google Analytics.
                <br/>В качестве альтернативного варианта, а также применительно к мобильным
                <br/>устройствам Вы можете использовать эту ссылку для отмены дальнейшей
                <br/>регистрации данных на этом сайте (такая опция применима только для текущего
                <br/>браузера и только для данного домена). При этом способе опция отключения
                <br/>сохраняется на Вашем гаджете. Если Вы очистите Ваш браузер от файлов ‘cookies’,
                <br/>то Вам нужно будет воспользоваться ссылкой ещё раз.
                <br/>Рекомендуем Вам ознакомиться с политикой Google (https://policies.google.com/?hl=ru)
                <br/>в области защиты данных для получения более подробной информации об
                <br/>отслеживании в сети Интернет.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>6. Технология DoubleClick</h3>
                <br/>Cервис, предоставляемый компанией Google Inc.
                <br/>(1600 Amphitheatre Parkway, Mountain View, CA 94043), который позволяет показывать вам
                <br/>релевантную рекламу. Для этой цели используются файлы cookies, которые не
                <br/>содержат никаких персональных данных. Ваш IP адрес используется для создания
                <br/>таких файлов. Cookies, используемые сервисом DoubleClick, присваивают вашему
                <br/>браузеру автоматически сгенерированные идентификационные номера и
                <br/>отслеживают возникающую рекламу. Это позволяет Google и партнерским сайтам
                <br/>показывать вам рекламу на основании посещения вами сайтов компании Henkel и
                <br/>других сайтов. Google передает к себе на сервер и хранит там информацию,
                <br/>собранную с помощью сервиса DoubleClick. Эти данные передаются третьим лицам
                <br/>только в случаях, определенных законодательством РК или согласием на обработку
                <br/>данных. Переходя на сайт Henkel, вы даете согласие на использование описанного
                <br/>выше сервиса и обработки данных компанией Google. Заблокировать передачу
                <br/>файлов Cookies вы можете в настройках вашего браузера. Если вы обычно не
                <br/>блокируете файлы Cookies, но в данном случае не хотели бы использовать
                <br/>технологию DoubleClick, перейдите по ссылке (www.google.com/settings/ads/onweb),
                <br/>скачайте и установите плагин, который деактивирует сервис DoubleClick компании
                <br/>Google Inc.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>7. Ваши пожелания и вопросы</h3>
                <br/>Хранящиеся данные будут стерты компанией «Хенкель» и/или её аффилированными
                <br/>лицами по истечении периода хранения, установленного законодательством или
                <br/>договором, либо в случае если сама компания «Хенкель» и/или её аффилированные
                <br/>лица отменит хранение тех или иных данных. Вы вправе в любое время потребовать
                <br/>удаления из базы данных компании «Хенкель» и/или её аффилированных лиц
                <br/>информации о Вас. Вы также вправе в любое время отозвать Ваше согласие на
                <br/>использование или переработку Ваших персональных данных. В таких случаях, а
                <br/>также, если у Вас есть какие-либо иные пожелания, связанные с Вашими
                <br/>персональными данными, просим Вас выслать письмо по почте в адрес Отдела
                <br/>корпоративных коммуникаций аффилированного лица «Хенкель» в Республики Казахстан
                <br/>– ТОО «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») по адресу:
                <br/>г.Алматы, Алмалинский р-н, ул.Масанчи, д.78, 050012
            </div>
            <Footer/>
        </div>
    )
}
