import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Internal = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Входные данные</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Входные данные</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Компания:</h3>
                ТОО «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас»)
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Юридический адрес компании:</h3>
                г.Алматы, Алмалинский р-н, ул.Масанчи, д.78, 050012
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Почтовый адрес:</h3>
                г.Алматы, Алмалинский р-н, ул.Масанчи, д.78, 050012
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Служба потребителей в Казахстане:</h3>
                ТОО «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас»)
                <br/><br/>тел: +7 7272 44 33 99
                <br/><br/> Более подробную информацию о компании Henkel:
                <br/><br/>www.henkel.com
            </div>
            <Footer/>
        </div>
    )
}
