import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const KZRights = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Құқықтық ережелер</title></Helmet>
            <div style={{maxWidth:800, marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Құқықтық ережелер</h2>
                <br/>Тиісті құқықтық құрылымның тұрақты техникалық жетілдірілуі мен өзгеруіне байланысты
                <br/>төменде келтірілген пайдалану шарттарын өзгерту және/немесе толықтыру қажеттілігі
                <br/>мерзімді түрде туындайды. Сондықтан біз пайдаланушылардан сайтқа әр рет кірудің
                <br/>алдында пайдалану шарттарын қарап шығуды және ықтимал өзгерістерді және/немесе
                <br/>толықтыруларды назарға алуды сұраймыз.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Кіріспе</h2>
                <br/>Бұл сайтты («Сайт») «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд
                <br/>Коукасас») ЖШС («Хенкель компаниясы») Алматы қ., Алмалы ауданы, Масанчи көш., 78,
                <br/>050012.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Сайтты пайдалана отырып, оған кіруші Сайтты пайдаланудың келесі шарттарымен</h2>
                <br/>қандай да бір шектеулерсіз немесе алып тастауларсыз келіседі.
                <br/>Сайтты пайдаланудың жалпы ережелері
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>1. Авторлық құқық</h3>
                <br/>Бұл Сайттың мазмұны авторлық құқықпен қорғалған. Атап айтқанда, электрондық
                <br/>бұқаралық ақпарат құралдарында орналастыруды немесе қайта өңдеуді қоса алғанда,
                <br/>осы сайттың мазмұнын үшінші тұлғалардың бөгде ресурстарда кез келген қайта
                <br/>жаңғыртуы, бейімдеуі, аударуы, орналастыруы және қайта өңдеуі авторлық құқықпен
                <br/>қорғалған. Мұндай ақпаратты толығымен немесе ішінара кез келген пайдалануға тек
                <br/>Хенкель компаниясының алдын ала жазбаша келісімімен ғана жол беріледі. Ақпаратты
                <br/>немесе деректерді кез келген түрде жаңғыртуға, атап айтқанда, мәтіндерді немесе
                <br/>мәтіндердің фрагменттерін немесе графикалық материалдарды (баспасөзде
                <br/>орналастырылған Хенкель компаниясына тиесілі фотосуреттерді қоспағанда) пайдалануға
                <br/>Хенкель компаниясының алдын ала жазбаша келісімімен ғана жол беріледі. Тарату және
                <br/>жаңғырту құқығы Хенкель компаниясына тиесілі.
                <br/><br/>Хенкель компаниясы Сайтта орналастырған фотосуреттерді тек редакциялық мақсаттарда
                <br/>пайдалануға болады.Қайта жаңғыртылатын және/немесе электрондық нұсқасына
                <br/>редакциялық мақсатта өзгерістер енгізілетін фотосуреттерде авторлық құқық белгіленуі
                <br/>болуы тиіс » © [жыл] «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд
                <br/>Коукасас») ЖШС. Барлық құқықтар қорғалған». Қайта басып шығару тегін, алайда Henkel
                <br/>компаниясына қайта басылып шығарылған материалдың көшірмесін жіберу керек.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>2. Тауарлық белгілер</h3>
                <br/>Хенкель компаниясының логотипі және барлық өнім атаулары және/немесе өнім
                <br/>дизайны тіркелген сауда белгілері немесе Хенкель компаниясының, оның еншілес және
                <br/>үлестес тұлғаларының, лицензиарлардың зияткерлік меншік объектілері болып табылады.
                <br/>Қазақстан Республикасының заңнамасында көзделген жағдайларды қоспағанда, Хенкель
                <br/>компаниясының немесе оған осындай келісім беруге уәкілеттік берілген үшінші
                <br/>тұлғалардың келісімінсіз осы материалдарды кез келген өзге де ресми немесе бейресми
                <br/>пайдалануға тыйым салынады және тауар таңбалары туралы заңнаманы, авторлық
                <br/>құқықты, өзге де зияткерлік меншік құқықтарын немесе жосықсыз бәсекелестік туралы
                <br/>заңнаманы бұзуды білдіреді.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>3. Үшінші тұлғалар сайттарының мазмұнымен байланыстан бас</h3>
                <br/>a. Осы сайттың беттерінде үшінші тұлғалар қолдау көрсететін және мазмұны Хенкель
                <br/>компаниясына белгісіз сілтемелер (яғни «гиперсілтемелер») бар. Хенкель компаниясы
                <br/>осындай сайттарға кіруге ғана ықпал етеді және олардың мазмұны үшін жауап бермейді.
                <br/>Біздің үшінші тұлғалардың сайттарына сілтемелеріміз сіз үшін осындай сайттарды шолуды
                <br/>жеңілдету үшін ғана арналған. Біздің Сайт сілтеме жасайтын беттердегі мәлімдемелер
                <br/>біздің мәлімдемелеріміз емес. Біз сілтемесі біздің сайтта берілген үшінші тұлғалардың кез
                <br/>келген сайттарының кез келген мазмұнымен байланыстан бас тартамыз. Атап айтқанда,
                <br/>біз осындай сайттардың беттерінде орын алған қолданыстағы заңнама нормаларын
                <br/>немесе үшінші тұлғалардың құқықтарын кез келген бұзушылықтар үшін жауапкершілікті
                <br/>өз мойнымызға алмаймыз.
                <br/><br/>b. Хенкель компаниясының Сайтынан кіруге болатын сайттарға қатысты, осы сайттардың
                <br/>иелері осы сайттардың мазмұны үшін, сондай-ақ осы сайттарда ұсынылған тауарларды
                <br/>сату және осындай сайттардың көмегімен интернет арқылы алынған кез-келген
                <br/>тапсырыстарды өңдеу және орындау үшін толық жауап береді.
                <br/><br/>c. Хенкель компаниясы авторлық құқықты, тауарлық белгілерге немесе басқа зияткерлік
                <br/>меншікке құқықтарды, сайтта орын алған жеке мүліктік емес құқықтарды бұзғаны үшін
                <br/>ешқандай жауапкершілік алмайды, оның сілтемесі Хенкель компаниясының сайтында
                <br/>бар.
                <br/><br/>d. Бұл бас тарту Сайтта көрсетілетін барлық сілтемелер үшін және пайдаланушы осындай
                <br/>сілтемелер арқылы жіберілген веб-сайттардың мазмұны үшін жарамды болып табылады.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>4. Жауапкершіліктен жалпы бас тарту</h3>
                <br/>Осы Сайтты пайдаланудың нәтижесінде пайда болған кез – келген жауапкершілік – заңды
                <br/>негізге қарамастан, деликтті қоса алғанда – нақты шығынмен шектеледі.
                <br/><br/>Бұл ережелер өнімнің сапасыздығы үшін жауапкершілік туралы заңнама нормаларына
                <br/>сәйкес Хенкель компаниясының жауапкершілігін немесе Хенкель компаниясы берген
                <br/>кепілдіктерден туындайтын жауапкершілікті қозғамайды. Жоғарыда аталған
                <br/>жауапкершілік шектеулері адамның өмірі мен денсаулығына зиян келтірілген жағдайда
                <br/>да қолданылмайды.
                <br/><br/>Хенкель компаниясы сайтты вирустардан қорғау үшін айтарлықтай күш салуда, бірақ біз
                <br/>олардың толық болмауына кепілдік бере алмаймыз. Осыған байланысты біз
                <br/>пайдаланушыларға Хенкель компаниясының сайтынан құжаттар мен деректерді жүктемес
                <br/>бұрын өз компьютерлерін вирустардан қорғау (вирус-сканерлерді пайдалану) бойынша өз
                <br/>бетінше шаралар қолдануға кеңес береміз.
                <br/>Хенкель компаниясы сайттың жеке опцияларының жұмысында қателер немесе
                <br/>жаңылыстардың болмауына кепілдік бермейді, сонымен қатар осы опциялардың қол
                <br/>жетімділігіне жауап бермейді.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>5. Ұзақ мерзімді сипаттағы мәлімдемелер және ниеттер туралы мәлімдемелер</h3>
                <br/>Хенкель компаниясының миссиясы мен мақсаттарына қатысты және ұзақ мерзімді
                <br/>сипатқа ие сайтта орналастырылған мәлімдемелер бізде бар соңғы ақпараттың және
                <br/>жасалған мәлімдемелердің дұрыстығына деген сенімнің негізінде берілген. Алайда,
                <br/>Хенкель компанияларының іс жүзінде қол жеткізген нәтижелері ұзақ мерзімді
                <br/>мәлімдемелерден айтарлықтай ерекшеленуі мүмкін, өйткені нәтижелер бірқатар
                <br/>жағдайларда Хенкель компаниясының бақылауынан тыс болатын бәсекелестік және
                <br/>макроэкономикалық сипаттағы бірқатар факторларға байланысты. Хенкель компаниясы
                <br/>сайттың беттерінде орналастырылған ұзақ мерзімді мәлімдемелерін үнемі түзетуге ниетті
                <br/>емес.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>6. Хенкель компаниясының өнімдері</h3>
                <br/>Осы сайттың беттерінде жарияланған өнімдер Хенкель компаниясының құрамына кіретін
                <br/>және әлемнің әртүрлі елдерінде орналасқан компаниялар шығаратын өнімдердің үлгісі
                <br/>болып табылады. Хенкель компаниясы нақты өнімнің сіздің елдің тұтынушылары үшін қол
                <br/>жетімді екендігіне кепілдік бермейді.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>7. Басқа ережелер</h3>
                <br/>Пайдаланудың келтірілген жалпы шарттары БҰҰ-ның тауарларды халықаралық сатып алу-
                <br/>сату шарттары туралы конвенциясын назарға алмастан, Қазақстан Республикасының
                <br/>заңнамасына сәйкес регламенттеледі және түсіндіріледі, сол арқылы осы конвенцияны
                <br/>пайдаланудың келтірілген жалпы шарттарына қатысты қолдану бірмәнді алып тасталады.
                <br/>Осы Сайтқа қатысты дауларды шешетін сот орны, мүмкіндігінше, қолданыстағы заңнамаға
                <br/>сәйкес, Қазақстан Республикасы болып табылады. Егер осы жалпы шарттардың қандай
                <br/>да бір ережесі жарамсыз болса, бұл қалған ережелердің жарамдылығына әсер етпеуі
                <br/>керек.
            </div>
            <Footer/>
        </div>
    )
}
