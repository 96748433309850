import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const KZInternal = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Деректерді енгізу</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Деректерді енгізу</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Компания:</h3>
                «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Кәсіпорынның заңды мекенжайы:</h3>
                Алматы қ., Алмалы ауданы, Масанчи көш., 78, 050012
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Пошталық мекенжай:</h3>
                Алматы қ., Алмалы ауданы, Масанчи көш., 78, 050012
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Қазақстандағы тұтынушыларға қызмет көрсету:</h3>
                «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС
                <br/><br/>тел: +7 7272 44 33 99
                <br/><br/> Henkel туралы қосымша ақпарат алу үшін:
                <br/><br/>www.henkel.com
            </div>
            <Footer/>
        </div>
    )
}
