import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const Personal = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Согласие на обработку персональных</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Согласие на обработку персональных данных</h2>
                <br/>Совершая регистрацию на сайте, пользователь подтверждает согласие на обработку
                <br/>своих персональных данных ТОО «Henkel Central Asia & Caucasus» («Хенкель Сентрал
                <br/>Эйша энд Коукасас»), далее «Заказчик», в соответствии с Политикой ТОО «Henkel Central
                <br/>Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») в отношении обработки
                <br/>персональных данных.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>В целях:</h3>
                <br/>
                <br/>✔ сбора информации о потребителях продукции Заказчика, в частности о ее
                <br/>качестве;
                <br/>✔ распространения информации о продукции Заказчика;
                <br/>✔ информирования участников о продукции Заказчика, а также о мероприятиях,
                <br/>проводимых и (или) организуемых Заказчиком мероприятия;
                <br/>✔ отправки любых других сообщений информационно-рекламного характера;
                <br/>✔ и сообщений с целью проведения маркетингового анализа и подготовки
                <br/>статистической информации.
                <br/><br/>Потребитель дает согласие ТОО «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша
                <br/>энд Коукасас»), включая лиц, действующих по его поручению, на осуществление
                <br/>обработки, включая сбор, запись, систематизацию, накопление, хранение, уточнение
                <br/>(обновление, изменение), использование, передачу (предоставление, доступ), следующих
                <br/>персональных данных:
                <br/><br/>● Имя
                <br/>● Фамилия
                <br/>● Электронная почта (адрес)
                <br/>● Мобильный телефон
                <br/><br/>Согласие Клиента действует до даты его отзыва путем направления Клиентом
                <br/>письменного заявления в адрес ТОО «Henkel Central Asia & Caucasus» («Хенкель Сентрал
                <br/>Эйша энд Коукасас») в соответствии с Политикой ТОО «Henkel Central Asia & Caucasus»
                <br/>(«Хенкель Сентрал Эйша энд Коукасас») в отношении обработки персональных данных.
            </div>
            <Footer/>
        </div>
    )
}
