import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const GERights = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>სამართლებრივი დებულებები</title></Helmet>
            <div style={{maxWidth:800, marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Terms of Use</h2>
                <br/>Due to ongoing technical innovations and changes of the relevant legal framework the
                <br/>following Terms of Use need to be changed and/or amended by us from time to time. We
                <br/>therefore ask the user to review these Terms of Use before each visit to this website and to
                <br/>take into account any changes and/or amendments.
                <br/><br/><h2 style={{fontWeight:"bold", margin:0}}>Introduction</h2>
                <br/>This website is brought to you by Henkel Central Asia & Caucasus, 78, Masanchi Street 050012,
                <br/>Almaty, Kazakhstan. The information available on this website about Henkel Central Asia &
                <br/>Caucasus (hereinafter referred to as "Henkel"), affiliated companies of Henkel and third parties
                <br/>has been put together with utmost care. However, we are unable to guarantee the
                <br/>completeness and correctness of the information. Henkel does not assume any liability for any
                <br/>mistakes in the contents of this website. Forward-looking statements made on this website
                <br/>have been given according to the best of our knowledge and belief. However, the results
                <br/>actually achieved by Henkel may differ greatly from these forward-looking statements, because
                <br/>they depend on a whole group of factors of a competitive and macroeconomic nature that are
                <br/>in some cases beyond the control of Henkel. Without prejudice to any legal obligations to
                <br/>amend forward-looking statements, Henkel has no intention of constantly updating all forward-
                <br/>looking statements contained in this website.
                <br/>By accessing this site, you accept without restriction or reservation the following General Terms
                <br/>of Use of the Website that you can view or print out.
                <br/>THIS WEBSITE AND THE INFORMATION CONTAINED HEREIN IS NOT INTENDED OR APPROVED
                <br/>FOR USE INSIDE THE UNITED STATES OF AMERICA OR U.S. CITIZENS OR RESIDENTS. SAID
                <br/>PERSONS ARE KINDLY ASKED TO CONTACT THEIR LOCAL HENKEL WEBSITE OR WEBSITES OF US-
                <br/>AFFILIATED COMPANIES OF HENKEL.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>General Terms of Use of the Website</h3>
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>1. Copyright Law</h3>
                <br/>The pages of our site (contents, structure) are protected by copyright. In particular, any
                <br/>reproductions, adaptations, translations, storage and processing in other media, including
                <br/>storage or processing by electronic means, enjoy copyright protection. Any exploitation in
                <br/>whole or in part thereof shall require the prior written consent of Henkel Central Asia &
                <br/>Caucasus. Any reproduction of information or data, in particular the use of texts or parts
                <br/>thereof or image materials (except for Henkel press photos) or any other exploitation or
                <br/>dissemination, requires Henkel's prior written consent. The rights of dissemination and
                <br/>reproduction are held by Henkel.
                <br/>Henkel press photos may be used for editorial purposes only. Henkel press photos that are
                <br/>reproduced and/or electronically modified for editorial purposes must bear the copyright
                <br/>notice "© 2024 Henkel Central Asia & Caucasus. All rights reserved".
                <br/>"© 2024 Henkel Central Asia & Caucasus. All rights reserved".
                <br/>Reprinting is free of charge, however, we request a copy for our files.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>2. Trademarks</h3>
                <br/>The Henkel Oval logo and Claim “Henkel - Exellence is our Passion”, and all product names
                <br/>and/or product get-ups on these pages are registered trademarks of Henkel Central Asia &
                <br/>Caucasus, its subsidiaries, affiliates or licensors. Any unauthorized use or abuse of these
                <br/>trademarks is expressly prohibited and constitutes a violation of trademark law, copyright law,
                <br/>other intellectual property rights or unfair competition law.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>3. Disclaimer for Third Party Websites</h3>
                <br/>a. The pages of this website contain links (i.e. "hyperlinks") to other websites that are operated
                <br/>by third parties and whose contents are not known to Henkel. Henkel merely facilitates access
                <br/>to such websites and assumes no responsibility whatsoever for their contents. Our links to third
                <br/>party websites are merely intended to make navigating easier for you. Statements shown on
                <br/>linked pages are not made our own. We rather explicitly dissociate from any and all contents of
                <br/>any and all third party pages linked on the pages of our website. In particular, we do not
                <br/>assume any liability for any breaches of statutory provisions or infringements of third party
                <br/>rights that occur on such pages.
                <br/>b. For websites to which hyperlinks are provided from the Henkel website, the owners of these
                <br/>websites are solely responsible for the content of these pages as well as for any sale of the
                <br/>products offered thereon and for the handling of any orders relating to the same.
                <br/>c. Henkel does not assume any liability for any infringement of any copyrights, trademarks or
                <br/>other intellectual property or personality rights that occurs on a page accessed by a hyperlink.
                <br/>d. In the event of an order or any other legal declaration in respect of a transaction, a contract
                <br/>is made solely between the user and the owner of the respective website or offering party or
                <br/>person presented therein, under no circumstances, however, between Henkel and the user.
                <br/>Please note the general business conditions of the respective supplier on the hyperlinked
                <br/>website.
                <br/>e. This disclaimer is valid for all links displayed on the henkel.com website and for any contents
                <br/>of websites to which the user is directed via such links.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>4. General Disclaimer</h3>
                <br/>Any liability of Henkel for damages arising out of the use of this website – irrespective of the
                <br/>legal cause, including tort – is limited to damages that are caused by intent or gross negligence.
                <br/>To the extent that there be a mandatory liability of Henkel as a result of a breach of material
                <br/>contractual duties, the total amount of any claim for damages is limited to the foreseeable
                <br/>damages. This shall not affect Henkel's liability under applicable Product Liability law or under
                <br/>any warranties given. The aforesaid limitations of liability also do not apply in the event of harm
                <br/>to life, bodily injury or harm to health of a person.
                <br/>Henkel employs great efforts to keep Henkel websites free from viruses, however, we are
                <br/>unable to guarantee any absence of viruses. For this reason, we recommend that care be taken
                <br/>to assure adequate protection against viruses (e.g. using virus scanners) before downloading
                <br/>documents and data.
                <br/>Henkel does not warrant any freedom from faults or errors of the services offered on the
                <br/>Henkel website nor their availability.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>5. Forecasts and Declarations of Intent</h3>
                <br/>Forward-looking statements on this Internet site have been made according to the best of our
                <br/>knowledge and belief. However, the results actually achieved by Henkel may differ greatly from
                <br/>these forward-looking statements, because they depend on a whole group of factors of a
                <br/>competitive and macroeconomic nature that are in some cases beyond the control of Henkel.
                <br/>Without prejudice to any legal obligations to amend forward-looking statements, Henkel has no
                <br/>intention of constantly updating all forward-looking statements contained in this website.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>6. Henkel Products</h3>
                <br/>The brands / products shown on this Internet website are examples of the brands / products
                <br/>available from companies of Henkel worldwide. Henkel does not warrant that a brands /
                <br/>product shown is also available in your country.
                <br/><h3 style={{fontWeight:"bold", margin:0}}><br/>Miscellaneous</h3>
                <br/>These General Terms of Use are governed by and construed in accordance with the laws of the
                <br/>Federal Republic of Germany without regard to the UN Convention on Contracts for the
                <br/>International Sale of Goods the application of which Convention to these General Terms of Use
                <br/>is hereby expressly excluded. To the extent permitted by applicable law, legal venue for all
                <br/>disputes relating to this website lies with Düsseldorf, Germany. Should any provision of these
                <br/>General Terms of Use be or become invalid, this shall not affect the validity of the remaining
                <br/>provisions.
            </div>
            <Footer/>
        </div>
    )
}
