import { ReactComponent as CameraLogo } from "../../assets/Black photo camera.svg"

export const Card = ({ item, setShowTryModal, setShopLinks }) => {
    return (
        <div style={{ fontFamily: "Montserrat", display: "flex", gap: "12px", flexDirection: "column" }}>
            <a href={item.link} className={"card-image"} style={{ position: "relative" }}>
                <div className={"card"}>
                    <img draggable={false} src={item.image} style={!item.image ? { display: "none" } : {}} />
                        {/*<div style={{ width: "100%", display: "flex", justifyContent: "center", backgroundColor: "#00000099", position: "absolute", bottom: 0, textTransform: "none"}}>*/}
                        {/*    <CameraLogo style={{ width: "24px", heigth: "24px" }} />*/}
                        {/*    <p>Uni sinab ko'ring</p>*/}
                        {/*</div>*/}
                </div>
            </a>
            <button className={"button-bye"} onClick={() => {
                window.location.replace(item.link);
                // setShowTryModal(true);
                // setShopLinks(item.shop_links)
            }} style={{ width: "100%", borderRadius: 0, padding:0, display:"flex", gap:"8px"}}><CameraLogo style={{ width: "24px", heigth: "24px", fill:"black" }} fill={"black"}/> Sinab ko'rish</button>
        </div>
    )
}
