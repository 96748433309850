import {useNavigate} from "react-router";

export const Footer = () => {
    const footer_items = [
        {label: "Huquqiy qoidalar", href:"/uz/rights"},
        {label: "Ma'lumotlarni kiritish", href:"/uz/internal"},
        {label: "Maxfiylik siyosati", href:"/uz/privacy"},
        {label: "Shaxsiy ma'lumotlarni qayta ishlashga rozilik", href:"/uz/personal"},
        {label: "Cookie-fayllar", href:"/uz/cookie"},
    ]
    const history = useNavigate();
    return(
        <div className={"footer"}>
            <footer>
                <div className={"container"}>
                    <div className={"footer-items"}>
                        {
                            footer_items.map((item, index) => {
                                return(
                                    <a onClick={() => {history(item.href); window.scrollTo(0,0)}} style={{cursor:"pointer"}}>{item.label}</a>
                                )
                            })
                        }
                    </div>
                    <div className={"padder-mobile"}>

                    </div>
                </div>

            </footer>
        </div>
    )
}
