import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const KZPersonal = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Дербес деректерді өңдеуге келісім</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Дербес деректерді өңдеуге келісім</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>1. Жалпы ережелер</h3>
                <br/>• Тапсырыс беруші өнімінің тұтынушылары туралы, атап айтқанда оның сапасы
                <br/>туралы ақпарат жинау;
                <br/>• Тапсырыс берушінің өнімі туралы ақпаратты тарату;
                <br/>• қатысушыларды Тапсырыс берушінің өнімдері туралы, сондай-ақ Тапсырыс беруші
                <br/>өткізетін және (немесе) ұйымдастыратын іс-шаралар туралы хабардар ету;
                <br/>• ақпараттық-жарнамалық сипаттағы кез келген басқа хабарламаларды жіберу;
                <br/>• маркетингтік талдау жүргізу және статистикалық ақпаратты дайындау мақсатында
                <br/><br/>«Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС-нің дербес
                <br/>деректерді өңдеуге қатысты саясатына сәйкес пайдаланушы сайтта тіркеле отырып
                <br/>«Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС-нің, одан
                <br/>әрі «Тапсырыс берушінің» (БСН 060940000796, 050012, Қазақстан Республикасы, Алматы
                <br/>қ., Масанчи к-сі, 78) оның дербес деректерін өңдеуіне келісімін растайды.
                <br/><br/>Тұтынушы «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС-
                <br/>не, оның тапсырмасы бойынша әрекет ететін тұлғаларды қоса алғанда келесі дербес
                <br/>деректерді жинауды, жазуды, жүйелеуді, жинақтауды, сақтауды, нақтылауды (жаңартуды,
                <br/>өзгертуді), пайдалануды, беруді (ұсыну, қолжетімділік), трансшекаралық беруді қоса
                <br/>алғанда өңдеуді жүзеге асыруға келісім береді:
                <br/><br/>• Аты
                <br/>• Тегі
                <br/>• Электрондық пошта (мекенжайы)
                <br/>• Ұялы телефон
                <br/><br/>«Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС-нің дербес
                <br/>деректерді өңдеуге қатысты саясатына сәйкес Клиенттің келісімі «Henkel Central Asia &
                <br/>Caucasus» («Хенкель Сентрал Эйша энд Коукасас») ЖШС-нің мекенжайына Клиенттің
                <br/>жазбаша өтінішін жіберу арқылы кері қайтарылған күнге дейін әрекет етеді.
            </div>
            <Footer/>
        </div>
    )
}
