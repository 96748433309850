import {Footer} from "./Footer";
import {Helmet} from "react-helmet";

export const UZInternal = () => {
    return(
        <div style={{width:"100%", display:"flex", justifyContent:"center", color:"white", flexDirection:"column", alignItems:"center"}}>
            <Helmet><title>Palette - Ma'lumotlarni kiritish</title></Helmet>
            <div style={{maxWidth:800, minHeight:"calc(100vh - 220px)", marginBottom:40}}>
                <br/><h2 style={{fontWeight:"bold", margin:0}}>Ma'lumotlarni kiritish</h2>
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Kompaniya:</h3>
                «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») MChJ
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Kompaniyaning yuridik manzili:</h3>
                Olmaota shahri, Olmali tumani, Masanchi ko‘chasi, 78-uy, 050012.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Pochta manzili:</h3>
                Olmaota shahri, Olmali tumani, Masanchi ko‘chasi, 78-uy, 050012.
                <br/><br/><h3 style={{fontWeight:"bold", margin:0}}>Korporativ aloqa:</h3>
                «Henkel Central Asia & Caucasus» («Хенкель Сентрал Эйша энд Коукасас») MChJ
                <br/><br/>tel: +7 7272 44 33 99
                <br/><br/> Henkel haqida ko’proq ma’lumot olish uchun:
                <br/><br/>www.henkel.com
            </div>
            <Footer/>
        </div>
    )
}
