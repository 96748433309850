import {useNavigate} from "react-router";

export const Footer = () => {
    const footer_items = [
        {label: "სამართლებრივი დებულებები", href:"/ge/rights"},
        {label: "შესაყვანი მონაცემები", href:"/ge/internal"},
        {label: "კონფედენციალურობა", href:"/ge/privacy"},
        {label: "თანხმობა პრესონალური მონაცემების დამუშავებაზე", href:"/ge/personal"},
        {label: "ქუქი", href:"/ge/cookie"},
    ]

    const history = useNavigate();
    return(
        <div className={"footer"}>
            <footer>
                <div className={"container"}>
                    <div className={"footer-items-ge"}>
                        {
                            footer_items.map((item, index) => {
                                return(
                                    <a onClick={() => {history(item.href); window.scrollTo(0,0)}}  style={{cursor:"pointer"}}>{item.label}</a>
                                )
                            })
                        }
                    </div>
                </div>
            </footer>
        </div>
    )
}
