import {useNavigate} from "react-router";

export const Footer = () => {
    const footer_items = [
        {label: "Правовые положения", href:"/ru/rights"},
        {label: "Входные данные", href:"/ru/internal"},
        {label: "Положения о конфиденциальности", href:"/ru/privacy"},
        {label: "Согласие на обработку персональных данных", href:"/ru/personal"},
        {label: "Файлы Cookie", href:"/ru/cookie"},
    ]
    const history = useNavigate();
    return(
        <div className={"footer"}>
            <footer>
                <div className={"container"}>
                    <div className={"footer-items"}>
                        {
                            footer_items.map((item, index) => {
                                return(
                                    <a onClick={() => {history(item.href); window.scrollTo(0,0)}} style={{cursor:"pointer"}}>{item.label}</a>
                                )
                            })
                        }
                    </div>
                </div>
            </footer>
        </div>
    )
}
